import { apiClient } from "@/taskpane/utils/client";
import { FolderData, SubFolderData } from "@/taskpane/types/folder";
import { handleError } from "@/taskpane/utils/errors";

/**
 * Retrieves the list of folders.
 * @returns A promise that resolves to an object containing an array of FolderData objects.
 */
export async function getFolders(): Promise<{ dossiers: FolderData[] } | null> {
  try {
    const response = await apiClient.get("/dossiers");
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossiers failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun dossiers récupérés`);
  }
}

/**
 * Retrieves the sub-folders of a given folder.
 * @param folderId The ID of the folder.
 * @returns A promise that resolves to an array of sub-folder data.
 */
export async function getSubFolders(folderId: string): Promise<{ sous_dossiers: SubFolderData[] } | null> {
  try {
    const response = await apiClient.get(`/dossier/${folderId}/sous-dossiers`)
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossier/${folderId}/sous-dossiers failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun sous-dossier n'a été trouvé ${folderId}`);
  }
}
