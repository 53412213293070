import { PropertyNode, PropertyType } from "@/taskpane/types/filiation";
import { StyledTooltip } from "@/taskpane/utils/tooltips";
import { TableCell, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export interface TableValueCellProps {
  node: PropertyNode;
  type: PropertyType;
  color?: "primary" | "warning" | "success";
}

export default function TableValueCell({ node, type, color }: TableValueCellProps) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const value = node.parents
    .filter((p) => p.type === type)
    .map((p) => p.value)
    .join(" / ");

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TableCell sx={{ position: "relative" }}>
      <StyledTooltip
        title={isOverflowing ? value : ""}
        type={color === "success" ? "success" : "warning"}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -15],
              },
            },
          ],
        }}
      >
        <Typography
          ref={ref}
          fontStyle="italic"
          fontSize={12}
          color={`${color}.600`}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace={"nowrap"}
          position="absolute"
          top={0}
          left={0}
          width="fit-content"
          maxWidth={1}
          height={1}
          py={0.5}
          px={1}
        >
          {value}
        </Typography>
      </StyledTooltip>
    </TableCell>
  );
}
