import * as React from "react";

import { Box, Typography } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export function TimelinePanelTabContent(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`full-width-tab-${index}`}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, width: 1 }}>
          <Typography sx={{ fontSize: "12px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
