// Function to rename the file extension to .pdf in lowercase
export function renamePdfExtension(fileName: string) {
    const extensionIndex = fileName.lastIndexOf('.');
  
    if (extensionIndex >= 0) {
      const basename = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);
  
      if (extension.toLowerCase() === '.pdf') {
        return `${basename}.pdf`;
      }
    }
    // Return the original file name if the extension is not .pdf
    return fileName;
  }
  
  