import { TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";
import { MdSearch } from "react-icons/md";

export interface StyledTextFieldProps {
  borderColor?: string;
}

export default function StyledTextField({
  borderColor = "rgba(145, 158, 171, 0.20)",
  ...props
}: StyledTextFieldProps & TextFieldProps) {
  const [shrink, setShrink] = useState(false);

  return (
    <TextField
      onFocus={() => setShrink(true)}
      onBlur={(e) => setShrink(!!e.target.value)}
      InputLabelProps={{ sx: { ml: 1.5 }, shrink }}
      {...props}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${borderColor}`,
          borderRadius: 2,
          px: 2.5,
        },
        "& .MuiInputBase-root": {
          padding: 0,
          fontSize: 11,
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink:not(.Mui-focused)": {
          marginTop: "2px",
        },
        "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
          marginTop: "-12px",
        },
        "& .MuiInputLabel-root": {
          fontSize: 11,
        },
        "& .MuiInputBase-input": {
          padding: "4px 12px 4px 5px",
          fontSize: 11,
        },
        svg: {
          marginLeft: 1,
          marginRight: 1,
          flexShrink: 0,
        },
        ...props.sx,
      }}
      InputProps={{
        startAdornment: <MdSearch size={16} color={borderColor} />,
        ...props.InputProps,
      }}
    />
  );
}
