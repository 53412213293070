import { useState } from "react";

/**
 * Custom hook for handling OTP input.
 *
 * @param length - The length of the OTP.
 * @returns An object containing the following properties and methods:
 *  - otp: An array of strings representing the OTP.
 *  - handleChangeOtp: A function that handles the change event of the OTP input.
 *  - setOtp: A function that sets the OTP (for copy and paste).
 */
export function useCrpcenInput(length = 4) {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const handleChangeOtp = (element: EventTarget & HTMLInputElement, index: number) => {
    if (!element.value) return;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };
  return {
    otp,
    handleChangeOtp,
    setOtp,
  };
}
