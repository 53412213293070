import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";


/* global Office */
/**
 * Redirect component that handles the redirection based on the current mailbox.
 * The main role of this component is to react to changes in the Office context,
 * such as when the add-in is initialized or when the mailbox changes in order to
 * update the state of the application as expected. 
 * For instance, when the mailbox changes, some page should be close and
 * user should be redirected to a page that is relevant to the new mailbox.
 * @param {{ children?: React.ReactNode }} props - The props object.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 * @returns {React.ReactNode} The child components to be rendered.
 */
export default function Redirect({ children }: { children?: React.ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation()
  const { changeCounter, currentMailbox, setFiliationData } = useOfficeContext();

  /**
   * Handles the search for filiation mail when a mail item changes.
   * this function is responsible for the good detection of a filiation mail.
   * @param {any} mailContext - The mail context.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  const handleSearchForFiliationMail = (mailContext: any) => {
    return new Promise((resolve) => {
      /**
       * In cased where the mailContext is null or undefined, we resolve the promise with null
       * and we redirect the user to the folders page if the current pathname match the pattern
       * "/folder/:folderId/filiation/:filiationId" that represent the filiation page.
       */
      if (!mailContext?.item) {
        resolve(null);
        if (location.pathname.includes("/folder/") && location.pathname.includes("/filiation/")) {
          navigate("/folders")
        }
        return;
      }

      let { body } = mailContext.item;

      body.getAsync(Office.CoercionType.Html, function (asyncResult: any) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const bodyHtml = asyncResult.value;

          const element = document.createElement("div");
          element.innerHTML = bodyHtml;

          const jsonData = element.querySelector("#filiation-data, #x_filiation-data")?.textContent;
          /**
           * If the parsing of the mail that should be a filiation mail failed, 
           * we resolve the promise with null and we redirect the user to the folders page only if 
           * the current pathname match the pattern "/folder/:folderId/filiation/:filiationId".
           */
          if (!jsonData) {
            resolve(null);
            if (location.pathname.includes("/folder/") && location.pathname.includes("/filiation/")) {
              navigate("/folders")
            }
            return;
          }

          const filiationData = JSON.parse(jsonData);
          const clientId = filiationData?.client_id;
          const folderId = filiationData?.dossier_id;
          const subFolderId = filiationData?.flow_id;

          /**
           * Feed the office context with the parsed filiation data from the open email
           * when a reaction to the event of email change detection is fire
           */
          setFiliationData({ ...filiationData })

          /**
           * If the parsing of the mail that should be a filiation mail succeeded,
           * but the clientId, folderId or subFolderId are null or undefined, due to a request
           * error or any other reason, we resolve the promise with null and 
           * we redirect the user to the folders page only if
           * the current pathname match the pattern "/folder/:folderId/filiation/:filiationId".
           */
          if (!clientId || !folderId || !subFolderId) {
            resolve(null);
            if (location.pathname === `/folder/${folderId}/filiation/${subFolderId}`) {
              navigate("/folders")
            }
            return;
          }

          /**
           * If the parsing of the mail that should be a filiation mail succeeded,
           * and the clientId, folderId and subFolderId are not null or undefined,
           * we resolve the promise with the filiationData and 
           * we redirect the user to the filiation page
           */
          navigate(`/folder/${folderId}/filiation/${subFolderId}`);

          /**
           * If the current pathname match the pattern "/folder/:folderId/filiation/:filiationId"
           * but the folderId and subFolderId are not the same as the one in the filiationData,
           * we redirect the user to the filiation page with the correct folderId and subFolderId.
           * This is to prevent the user from being on the wrong filiation page.
           */
          if (
            location.pathname.includes("/folder/") &&
            location.pathname.includes("/filiation/") &&
            location.pathname !== `/folder/${folderId}/filiation/${subFolderId}`
          ) {
            navigate("folders")
          }

          /**
           * If all the prerequisites failed and the current pathname match the pattern
           * "/folder/:folderId/filiation/:filiationId", we redirect the user to the folders page.
           */
        } else if (location.pathname.includes("/folder/") && location.pathname.includes("/filiation/")) {
          navigate("/folders")
        }
      });
    });
  };

  /**
  * This useEffect is used to handle the search for filiation mail when the component is mounted.
  */
  useEffect(() => {
    handleSearchForFiliationMail(currentMailbox);
  }, []);

  /**
  * This useEffect is used to handle the search for filiation mail when the mail item changes.
  * The changeCounter is used to trigger the useEffect when the mail item changes if currenMailbox
  * failed to dectect the change.
  */
  useEffect(() => {
    handleSearchForFiliationMail(currentMailbox)
  }, [changeCounter, currentMailbox]);

  return children;
}
