import { refreshAuthTokens } from "@/taskpane/services/auth";
import { Tokens } from "@/taskpane/types/auth";
import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API_ADDIN,
});

export const TOKEN_STORAGE_KEY = "loggedInUserTokensByByLaw";

apiClient.interceptors.request.use(async (config) => {
  try {
    const storedTokens = await getAuthTokens();
    if (storedTokens) {
      config.headers.Authorization = `Bearer ${storedTokens.IdToken}`;
    }
  } catch {
    localStorage.clear();
  }
  return config;
});

export const cognitoClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_COGNITO,
});

/**
 * Saves the authentication tokens to the local storage.
 * @param tokensData - The authentication tokens data to be saved.
 * @returns The saved authentication tokens.
 */
export function saveAuthTokens(tokensData: Omit<Tokens, "ExpiresOn">) {
  const tokens: Tokens = {
    ...tokensData,
    ExpiresOn: new Date(Date.now() + tokensData.ExpiresIn * 1000).toISOString(),
  };
  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(tokens));
  return tokens;
}

/**
 * Retrieves the authentication tokens from local storage.
 *
 * @returns A promise that resolves to the authentication tokens or null if no tokens are found.
 */
export async function getAuthTokens(): Promise<Tokens | null> {
  const storedValue = localStorage.getItem(TOKEN_STORAGE_KEY);
  if (!storedValue) return null;
  const tokens: Tokens = JSON.parse(storedValue);
  try {
    const expiresOn = new Date(tokens.ExpiresOn);
    // refresh token 5 minutes before expiration
    if (expiresOn.getTime() < Date.now() + 300_000) {
      const t = await refreshAuthTokens(tokens.RefreshToken);
      return saveAuthTokens({ ...t, RefreshToken: tokens.RefreshToken });
    }
  } catch {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    return null;
  }

  return tokens;
}
