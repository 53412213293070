import { useMemo } from "react";

import { useGetPublicFiliationQuery } from "@/taskpane/services/filiation.hook";

import type { Property, PropertyNode } from "@/taskpane/types/filiation";


/**
 * @description - Custom hook for retrieving and formatting filiation data.
 *
 * @param clientId - The ID of the folder.
 * @param dossierId - The ID of the folder.
 * @param sousDossierId - The ID of the folder.
 * @param flowId - The ID of the subfolder.
 *
 * @returns An object with the following properties:
 *  - communes: An array of communes.
 *  - lots: An array of lots.
 *  - parcellesWithNoChildren: An array of parcelles with no children.
 *  - volumesWithNoChildren: An array of volumes with no children.
 *  - getValidatedProperties: A function that returns the properties with a "chosen" property based on the selected IDs.
 */
export function usePublicFiliationData(clientId: string, dossierId: string, sousDossierId: string, flowId: string, filteredFiliationDataIds?: number[]) {
	const { data: propertiesData, isLoading }: any = useGetPublicFiliationQuery(
		clientId,
		dossierId,
		sousDossierId,
		flowId,
		{ enabled: clientId !== "" && dossierId !== "" && sousDossierId !== "" && flowId !== "" && !!filteredFiliationDataIds }
	);

	const properties = (propertiesData && propertiesData["nodes"]) ?? [];
	const communes = useMemo(() => properties?.filter((p: { type: string }) => p.type === "commune"), [properties]);
	const parcelles = useMemo(() => properties?.filter((p: { type: string }) => p.type === "cadastre"), [properties]);
	const volumes = useMemo(() => properties?.filter((p: { type: string }) => p.type === "volume"), [properties]);

	const parcellesWithNoChildren = useMemo(() => {
		const filteredParcelles = parcelles
			.filter((p: { children_ids: string | any[] }) => p.children_ids.length === 0)
			.map((p: any) => ({ ...p, children: [], parents: [] }));
		if(filteredFiliationDataIds !== undefined) {
			return filteredParcelles.filter((p: { id: number }) => filteredFiliationDataIds.includes(p.id));
		} else {
			return filteredParcelles;
		}
	}, [parcelles]);

	const volumesWithNoChildren = useMemo(() => {
		const filtredVolumes = volumes
			.filter((v: { children_ids: string | any[] }) => v.children_ids.length === 0)
			.map((v: Property) => ({
				...v,
				children: [],
				parents: v.parents_ids
					.map((id) => parcelles.find((p: { id: number }) => p.id === id)!)
					.map((p) => ({ ...p, children: [], parents: [] })),
			}));
		if(filteredFiliationDataIds !== undefined) {
			return filtredVolumes.filter((v: { id: number }) => filteredFiliationDataIds.includes(v.id));
		} else {
			return filtredVolumes;
		}
	}, [volumes]);

	const lots = useMemo(() => {
		const lotsData = properties
			?.filter((p: { type: string }) => p.type === "lot")
			?.map((p: any) => ({ ...p, children: [], parents: [] })) as PropertyNode[];

		for (const volume of volumes) {
			for (const lot of lotsData) {
				if (lot.parents_ids.includes(volume.id) && !lot.parents.some((p) => p.id === volume.id)) {
					lot.parents.push({ ...volume, children: [], parents: [] });
				}
			}
		}

		for (const parcelle of parcelles) {
			for (const lot of lotsData) {
				for (const volume of lot.parents) {
					if (volume.parents_ids.includes(parcelle.id) && !lot.parents.some((p) => p.id === parcelle.id)) {
						lot.parents.push({ ...parcelle, children: [], parents: [] });
					}
				}
				if (lot.parents_ids.includes(parcelle.id) && !lot.parents.some((p) => p.id === parcelle.id)) {
					lot.parents.push({ ...parcelle, children: [], parents: [] });
				}
			}
		}

		if(filteredFiliationDataIds !== undefined) {
			return lotsData.filter((l: { id: number }) => filteredFiliationDataIds.includes(l.id));
		} else {
			return lotsData;
		}
	}, [properties]);

	return {
		communes,
		lots,
		parcellesWithNoChildren,
		volumesWithNoChildren,
		getValidatedProperties: (selected: number[]) => {
			return properties.map((p: { id: number }) => ({ ...p, chosen: selected.includes(p.id) }));
		},
		isLoading,
	};
}