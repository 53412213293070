import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";

export interface Step {
  title: string;
  subtitle: string;
  component?: React.ReactNode;
}

export interface VerticalStepperProps {
  steps: Step[];
  currentStep: number;
}

function VerticalStepperStep({
  step,
  isCurrent,
  index,
  isDone,
}: {
  step: Step;
  isCurrent: boolean;
  index: number;
  isDone: boolean;
}) {
  const getColor = useCallback(
    (current: string, disabled: string, done: string) => {
      if (isCurrent) {
        return current;
      }
      if (isDone) {
        return done;
      }
      return disabled;
    },
    [isCurrent, isDone]
  );

  return (
    <Stack direction="row" pb={1} spacing={1} width={1}>
      <Stack width={30} flexShrink={0} alignItems="center" spacing={1} pt={1}>
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: "50%",
            bgcolor: getColor("primary.main", "#DFE3E8", "success.main"),
            color: getColor("white", "text.secondary", "white"),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {index + 1}
        </Box>
        <Box
          sx={{
            width: "1px",
            bgcolor: getColor("primary.main", "#DFE3E8", "success.main"),
            flex: 1,
          }}
        />
      </Stack>
      <Stack pb={1} spacing={1} width={1}>
        <Typography fontWeight="bold" fontSize={14} color={getColor("text.primary", "text.disabled", "success.main")}>
          {step.title}
        </Typography>
        <Typography fontSize={12} color={getColor("text.secondary", "text.disabled", "success.main")}>
          {step.subtitle}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: isCurrent ? "1fr" : "0fr",
            width: "100%",
            overflow: "hidden",
            transition: "grid-template-rows 0.3s",
          }}
        >
          <Box overflow="hidden">{step.component}</Box>
        </Box>
      </Stack>
    </Stack>
  );
}

export default function VerticalStepper({ steps, currentStep }: VerticalStepperProps) {
  return (
    <Stack width={1} height={0.92}>
      {steps.map((step, index) => (
        <VerticalStepperStep
          key={index}
          step={step}
          isCurrent={index === currentStep}
          index={index}
          isDone={index < currentStep}
        />
      ))}
    </Stack>
  );
}
