import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

/**
 * Renders a button component with optional loading state.
 *
 * @component
 * @param {ButtonProps & { loading?: boolean }} props - The button props and loading state.
 * @param {boolean} props.loading - Determines if the button is in a loading state.
 * @param {boolean} props.disabled - Determines if the button is disabled.
 * @param {ReactNode} props.children - The content of the button.
 * @returns {JSX.Element} The rendered button component.
 */
export default function LoadingButton({
  loading,
  disabled,
  children,
  ...props
}: ButtonProps & {
  loading?: boolean;
}) {
  return (
    <Button disabled={disabled || loading} {...props}>
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
}
