import dayjs from "dayjs";


import type {
	FiliationEvent,
	FiliationNode,
	FiliationEventWithFiliationStateGroupedByDate,
} from "@/diagramme/types";

/**
 * @description -  Hook that group events by dates and compute every filiation state for each of these dates
 *
 * @param {FiliationEvent[]} events - Filiation events related to chosen nodes and their related nodes.
 * @param {FiliationNode[]} originesByDates - Filiation nodes representing the filiation state for each date.
 *
 * @returns {FiliationEventWithFiliationStateGroupedByDate[]} - An array of filiation events grouped by dates containing filiation state for every date and related events.
 */
export const getFiliationEventsGroupedByDates = (
	events: FiliationEvent[],
	originesByDates: any
): FiliationEventWithFiliationStateGroupedByDate[] => {
	// We inialize an array for all unique dates
	const uniqueDates: string[] = [];

	// We loop through all events to get all dates and insert only dates that not already in uniqueDates
	events.forEach((event: FiliationEvent) => {
		const { date_event } = event;

		if (!uniqueDates.includes(date_event)) {
			uniqueDates.push(date_event);
		}
	});

	// We initialize a variable for holding last computed filiation state
	// let lastComputedFiliationState: FiliationNode[] = [];

	// We initialize filiation events grouped by dates by mapping our uniquesDates that will contain each { date: Date, events: FiliationEvent[] } objects
	const filiationEventsGroupedByDate: FiliationEventWithFiliationStateGroupedByDate[] = uniqueDates.map(
		(date: string) => {
			// We filter events by date
			const eventsByDate = events
				.filter((event: FiliationEvent) => event.date_event === date)
				?.map((event: FiliationEvent) => {
					return {
						...event,
						filiationState: event?.event_descripion?.final_nodes,
					};
				});

			// We return each date and related events objects without value for computed matching date filaition state
			return {
				date,
				events: eventsByDate,
				origine: originesByDates[date]?.nodes,
			};
		}
	);

	const filiationEventsSortedByDate = filiationEventsGroupedByDate.sort((a, b) => {
		return dayjs(b.date, "DD/MM/YYYY").valueOf() - dayjs(a.date, "DD/MM/YYYY").valueOf();
	});

	// We need to update the state of the filiation for each date with the associated events infos.
	return filiationEventsSortedByDate.map((filiationDate) => {

		return {
			...filiationDate,
			events: filiationDate?.events.map((event) => {
				return {
					...event,
					filiationState: [...filiationDate?.origine],
				};
			}),
		};
	});
};