import React, { useMemo } from "react";

import { Box, Button, Drawer, Tabs, Tab, useTheme } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { TimelineEventPanel } from "./timeline-panel-drawer";

import {
  BLUE_BYLAW,
  BORDER_RADIUS_MUI,
  DEFAULT_ELEVATION,
  DEFAULT_PADDING,
  LOGO_BOX_WIDTH,
  PANEL_WIDTH,
  TOOLBAR_HEIGHT,
  WHITE_DARK,
} from "@/diagramme/config";

import type { FiliationEventWithFiliationStateGroupedByDate } from "@/diagramme/types";

export const TimeLinePanel = ({ data, setCurrentDate, today }: any) => {
  const [selectedTab, setSelectedTab] = React.useState(() => data?.length - 1);
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();

  const drawerAchoredRef = React.useRef(null);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const dateInGoodOrder = useMemo(() => {
    return data?.reverse();
  }, [data]);


  return (
    <Box
      sx={{
        bgcolor: WHITE_DARK,
        borderRadius: BORDER_RADIUS_MUI,
        display: "flex",
        flexDirection: "column",
        height: 1,
        paddingLeft: 0,
        paddingRight: 0,
        width: 1,
        mr: "168px",
      }}
    >
      <Box
        ref={drawerAchoredRef}
        sx={{
          alignItems: "center",
          borderRadius: BORDER_RADIUS_MUI,
          display: "flex",
          height: 1,
          justifyContent: "space-between",
          padding: 0,
          width: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            height: 1,
            padding: 0,
            borderRadius: BORDER_RADIUS_MUI,
            backgroundColor: WHITE_DARK,
            maxWidth: `calc(100vw - ${LOGO_BOX_WIDTH + 156}px)`,
          }}
        >
          <Tabs
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            value={selectedTab}
            sx={{
              backgroundColor: WHITE_DARK,
              borderBottom: "none!important",
              position: "relative",
              width: 1,
              padding: 0,
            }}
            TabScrollButtonProps={{
              sx: {
                px: "-50px",
                "&.MuiTabScrollButton-root": {
                  borderRadius: BORDER_RADIUS_MUI,
                  mx: 0,
                },
                "& .MuiSvgIcon-root": {
                  color: `${BLUE_BYLAW}`,
                  width: "25px",
                  height: "25px",
                },
              },
            }}
          >
            {dateInGoodOrder?.map(
              (filiationByDate: FiliationEventWithFiliationStateGroupedByDate, index: number, arr: any[]) => {
                return (
                  <Tab
                    key={`tab-${filiationByDate.date}-${index}`}
                    label={
                      <Box
                        sx={{
                          width: 1,
                          height: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: WHITE_DARK,
                            position: "absolute",
                            left: index === 0 ? "-200px" : "-100px",
                            right: index === arr.length - 1 ? "0px" : "-50px",
                            mr: index === 0 ? "25px" : "0",
                            width: "100px",
                            height: "28px",
                            zIndex: "1000!important",
                            borderLeft: `2px solid ${theme.palette.text.secondary}`,
                            borderRight: `2px solid ${theme.palette.text.secondary}`,
                            userSelect: "none",
                            pointerEvents: "none",
                          }}
                        >
                          {filiationByDate.date}
                        </Box>
                        {/* {index === arr.length ? null : ( */}
                        <Button
                          color={index === selectedTab ? "primary" : "inherit"}
                          variant="outlined"
                          size="small"
                          sx={{ height: "28px" }}
                        >
                          {index == selectedTab ? (
                            <VisibilityIcon color="primary" />
                          ) : (
                            <VisibilityOffIcon color="disabled" />
                          )}
                        </Button>
                        {/* )} */}
                      </Box>
                    }
                    onClick={() => setCurrentDate(filiationByDate.date)}
                    value={index}
                    sx={{
                      fontSize: 14,
                      p: 0,
                      borderRadius: 0,
                      ml: index === 0 ? "25px" : "50px",
                      paddingRight: index === 0 ? "25px" : "0",
                      paddingLeft: index === arr.length - 1 ? "0" : "0",
                      mr: index === arr.length - 1 ? "0" : "50px",
                      overflow: "visible",
                      "&.MuiButtonBase-root": {
                        position: "relative",
                        width: index === 0 ? "auto" : "128px",
                      },
                      "&.Mui-selected": {
                        backgroundColor: WHITE_DARK,
                        border: `none`,
                        borderRadius: index === 0 ? `${BORDER_RADIUS_MUI}px 0 0 ${BORDER_RADIUS_MUI}px` : 0,
                        color: index === selectedTab ? BLUE_BYLAW : "text.secondary",
                        fontWeight: 700,
                      },
                    }}
                  />
                );
              }
            )}
          </Tabs>
        </Box>
        <Button
          onClick={() => toggleDrawer(!open)}
          variant="contained"
          color="primary"
          size="small"
          sx={{ height: "36px", position: "absolute", right: "25px" }}
        >
          {`${open ? "Masquer le détail" : "Afficher le détail"}`}
        </Button>
      </Box>

      <Drawer
        anchor={"right"}
        variant="persistent"
        elevation={DEFAULT_ELEVATION}
        open={open}
        onClose={() => toggleDrawer(false)}
        hideBackdrop={true}
        PaperProps={{
          elevation: DEFAULT_ELEVATION,
          sx: {
            top: `${DEFAULT_PADDING + TOOLBAR_HEIGHT}px`,
            right: `${DEFAULT_PADDING}px`,
            bottom: `${DEFAULT_PADDING}px`,
            height: "unset",
            borderRadius: BORDER_RADIUS_MUI,
            width: `${PANEL_WIDTH}px`,
            backgroundColor: "rgba(255,255,255,1)",
          },
        }}
        ModalProps={{ sx: { position: "unset" } }}
      >
        <TimelineEventPanel data={data} selectedTab={selectedTab} />
      </Drawer>
    </Box>
  );
};
