import { Dataroom } from "@/taskpane/types/notary-space";
import { CloseOutlined } from "@mui/icons-material";
import { Card, FormControlLabel, IconButton, Radio, Stack, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

export interface NotarySpaceDataroomProps {
  datarooms: Dataroom[];
  selectedDataroom?: string;
  setSelectedDataroom: (dataroom: string) => void;
}

export default function NotarySpaceDataroom({
  datarooms,
  selectedDataroom,
  setSelectedDataroom,
}: NotarySpaceDataroomProps) {
  const [search, setSearch] = useState("");

  const filteredDatarooms = useMemo(
    () => datarooms?.filter((dr) => dr.dataroom_name.toLowerCase().includes(search.toLowerCase())) || [],
    [datarooms, search]
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stack height={1}>
        <TextField
          label="Rechercher une dataroom..."
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: search && (
              <IconButton size="small" onClick={() => setSearch("")}>
                <CloseOutlined />
              </IconButton>
            ),
          }}
        />

        <Stack sx={{ overflowY: "auto", height: "calc(100vh - 470px)" }}>
          {!filteredDatarooms.length && (
            <Typography fontWeight="bold" color="grey.400" textAlign="center" p={3}>
              Aucune dataroom correspondant à ces critères n’a été trouvée
            </Typography>
          )}
          {filteredDatarooms.map((dr) => (
            <FormControlLabel
              key={dr.dataroom_id}
              control={
                <Radio
                  size="small"
                  checked={selectedDataroom === dr.dataroom_id}
                  color="secondary"
                  onChange={() => setSelectedDataroom(dr.dataroom_id)}
                />
              }
              label={dr.dataroom_name}
              slotProps={{ typography: { fontSize: 12 } }}
            />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
}
