import { isEspaceNotarialStatus, mapping_status } from "@/taskpane/utils/notary-space";
import { StyledTooltip } from "@/taskpane/utils/tooltips";
import { SubFolder } from "@/taskpane/types/folder";
import { Radio, useTheme } from "@mui/material";
import React from "react";
import { BsShieldFillCheck } from "react-icons/bs";

export default function SubFolderAction({
  sf,
  selectedSubFolder,
  setSelectedSubFolder,
}: {
  sf: SubFolder;
  selectedSubFolder: SubFolder | undefined;
  setSelectedSubFolder: (subFolder: SubFolder) => void;
}) {
  const theme = useTheme();

  // Use the mapping table to display the correct shield or radio button
  if (isEspaceNotarialStatus(sf.espace_notarial_status)) {
    if (mapping_status[sf.espace_notarial_status].new_connection_possible) {
      return (
        <Radio
          size="small"
          checked={selectedSubFolder?.sous_dossier_id === sf.sous_dossier_id}
          color="secondary"
          onChange={() => setSelectedSubFolder(sf)}
        />
      );
    } else {
      let shieldColor = theme.palette.info.main;
      if (mapping_status[sf.espace_notarial_status]["shield_type"] === "error") {
        shieldColor = "#ff0000";
      } else if (mapping_status[sf.espace_notarial_status]["shield_type"] === "success") {
        shieldColor = theme.palette.success.main;
      } else if (mapping_status[sf.espace_notarial_status]["shield_type"] === "warning") {
        shieldColor = theme.palette.warning.main;
      }

      return (
        <StyledTooltip
          title={mapping_status[sf.espace_notarial_status].shield_label}
          type={mapping_status[sf.espace_notarial_status].shield_type}
        >
          <span>
            <BsShieldFillCheck style={{ margin: "6px 10px 6px 10px" }} size={20} color={shieldColor} />
          </span>
        </StyledTooltip>
      );
    }
  } else {
    return (
      <Radio
        size="small"
        checked={selectedSubFolder?.sous_dossier_id === sf.sous_dossier_id}
        color="secondary"
        onChange={() => setSelectedSubFolder(sf)}
      />
    );
  }
}
