import React from "react";

import { Box, Stack } from "@mui/material";
import Logo from "@/taskpane/components/logo/Logo";

export function Header() {
  return (
    <Box sx={{ px: 1, pt: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Logo />
      </Stack>
    </Box>
  );
}
