import { styled, Tooltip, TooltipProps } from "@mui/material";
import React from "react";

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const ErrorTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  borderColor: theme.palette.error.dark,
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: "white",
  color: theme.palette.error.dark,
  borderRadius: 8,
  fontSize: 10,
}));

export const WarningTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  borderColor: theme.palette.warning.dark,
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: "white",
  color: theme.palette.warning.dark,
  borderRadius: 8,
  fontSize: 10,
}));

export const SuccessTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  borderColor: theme.palette.success.dark,
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: "white",
  color: theme.palette.success.dark,
  borderRadius: 8,
  fontSize: 10,
}));

export const DisabledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  borderColor: theme.palette.grey[500],
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: "white",
  color: theme.palette.grey[500],
  borderRadius: 8,
  fontSize: 10,
}));

/**
 * Renders a styled tooltip based on the provided type.
 *
 * @param type - The type of the tooltip. Can be one of "error", "warning", "success", "disabled", or "primary".
 * @param props - Additional props to be passed to the tooltip component.
 * @returns The rendered tooltip component based on the provided type.
 */
export const StyledTooltip = ({
  type,
  ...props
}: TooltipProps & { type: "error" | "warning" | "success" | "disabled" | "primary" }) => {
  switch (type) {
    case "error":
      return <ErrorTooltip {...props} />;
    case "warning":
      return <WarningTooltip {...props} />;
    case "success":
      return <SuccessTooltip {...props} />;
    case "disabled":
      return <DisabledTooltip {...props} />;
    default:
      return <Tooltip {...props} />;
  }
};
