import { UploadableFile } from "@/taskpane/types/file";
import { useState } from "react";

/**
 * Reads an uploaded file as base64.
 *
 * @param file - The file to be read.
 * @returns A promise that resolves with the base64 representation of the file.
 */
const readUploadedFileAsBase64 = (file: File) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.addEventListener("load", function () {
      const base64 = temporaryFileReader.result;
      resolve(base64);
    });

    temporaryFileReader.readAsDataURL(file);
  });
};

/**
 * Generates a random ID like "b4p9f0o".
 *
 * @returns {string} The generated random ID.
 */
function generateRandomId() {
  return Math.random().toString(36).substring(7);
}

/**
 * Custom hook for handling file drag and drop functionality.
 *
 * @param selectFiles - A function that will be called with an array of files when files are selected.
 * @returns An object with the following properties and methods:
 *   - files: An array of UploadableFile objects with an additional "isPdf" property.
 *   - onDrop: An asynchronous function that handles the drop event and processes the selected files.
 *   - resetFiles: A function that resets the files array to an empty array.
 */
export function useFileDnd(selectFiles: (files: (UploadableFile & { isPdf: boolean })[]) => void) {
  const [files, setFiles] = useState<(UploadableFile & { isPdf: boolean })[]>([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  return {
    files,
    async onDrop(selectedFiles: File[]) {
      const newFiles = await Promise.all(
        selectedFiles.map(async (file) => {
          const result = await readUploadedFileAsBase64(file);
          const base64 = result as string;

          return {
            id: `${generateRandomId()}-${file.name}`,
            name: file.name,
            size: file.size,
            base64,
            type: file.type,
            isPdf: file.type === "application/pdf" || file.name.toLowerCase().endsWith(".pdf"),
          };
        })
      );

      selectFiles(newFiles.filter((f) => f.isPdf));

      setForceUpdate(!forceUpdate);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    resetFiles() {
      setFiles([]);
    },
  };
}
