import { alpha, styled } from "@mui/material/styles";
import { MaterialDesignContent } from "notistack";

export const StyledNotistack = styled(MaterialDesignContent)(({ theme }) => {
  const lightMode = theme.palette.mode === "light";

  return {
    "& #notistack-snackbar": {
      ...theme.typography.subtitle2,
      padding: 0,
      flexGrow: 1,
    },
    "&.notistack-MuiContent": {
      color: theme.palette.text.primary,
      borderRadius: 12,
      boxShadow: "0px 8px 16px 0px rgba(145, 158, 171, 0.16)",
      padding: theme.spacing(0.5, 2, 0.5, 0.5),
      backgroundColor: theme.palette.background.paper,
    },
    "&.notistack-MuiContent-default": {
      padding: theme.spacing(1, 2, 1, 1),
      color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
      backgroundColor: lightMode ? theme.palette.grey[800] : theme.palette.common.white,
    },
  };
});

type StyledIconProps = {
  color: "info" | "success" | "warning" | "error";
};

export const StyledIcon = styled("span")<StyledIconProps>(({ color, theme }) => ({
  width: 44,
  height: 44,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1.5),
  flexShrink: 0,
  color: theme.palette[color].main,
  borderRadius: 12,
  backgroundColor: alpha(theme.palette[color].main, 0.16),
}));
