import { getUserData, setUserCrpcen } from "@/taskpane/services/user";
import { MutationOptions, useMutation, useQuery, UseQueryOptions, useQueryClient } from "@tanstack/react-query";

export const useUpdateCrpcenMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof setUserCrpcen>>, Error, Parameters<typeof setUserCrpcen>[0]>
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...opt,
    mutationFn: setUserCrpcen,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["getUserAttributes"],
      });
      if (opt?.onSuccess) opt.onSuccess(data, variables, context);
    },
  });
};

// This hook fetches user data and caches it for 1 hour.
// The data will be considered fresh for 55 minutes (staleTime),
// and will be eligible for garbage collection after 1 hour (gcTime) even if unused.
// This reduces unnecessary API calls while ensuring relatively up-to-date data.
export const useGetUserDataQuery = (opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserData>>>>) =>
  useQuery({
    queryKey: ["getUserData"],
    queryFn: getUserData,
    staleTime: 55 * 60 * 1000, // 55 minutes
    gcTime: 60 * 60 * 1000, // 1 hour
    ...opt,
  });
