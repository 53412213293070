import React, { useEffect, useState } from "react";

import { WarningTooltip } from "@/taskpane/utils/tooltips";
import CrpcenInputCode from "@/taskpane/components/input-code/CrpcenInputCode";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { useUpdateCrpcenMutation } from "@/taskpane/services/user.hook";
import { useGetUserAttributesQuery } from "@/taskpane/services/auth.hook";

export default function CrpcenPage() {
  const [otp, setOtp] = useState("");
  const user = useGetUserAttributesQuery();
  const [crpcenAsked, setCrpcenAsked] = useState(false);
  const updateCrpcen = useUpdateCrpcenMutation({
    onSuccess: () => {
      setCrpcenAsked(true);
    },
  });

  useEffect(() => {
    if (user.data?.crpcen?.includes("NEED_ACCEPT")) {
      setCrpcenAsked(true);
    }
  }, [user.data]);

  return (
    <Box className="container">
      {crpcenAsked ? (
        <Stack spacing={3} alignItems="center">
          <Card sx={{ margin: "auto", width: "80%" }}>
            <Stack spacing={3} p={2} alignItems="center">
              <Typography
                variant="subtitle1"
                gutterBottom
                color="black"
                fontWeight={600}
                textAlign="center"
                lineHeight={1.4}
              >
                Votre administrateur{" "}
                {updateCrpcen.data?.admin_email && (
                  <>
                    (<i>{updateCrpcen.data?.admin_email || ""}</i>)
                  </>
                )}{" "}
                à reçu une demande d’activation de votre compte.
                <br />
                <br />
                Revenez ici lorsque la demande aura été validée.
              </Typography>
            </Stack>
          </Card>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Compris
          </Button>
        </Stack>
      ) : (
        <Card sx={{ margin: "auto", width: "80%" }}>
          <Stack spacing={3} p={2} alignItems="center">
            <Typography
              variant="subtitle1"
              gutterBottom
              color="black"
              fontWeight={600}
              textAlign="center"
              lineHeight={1.4}
            >
              Veuillez renseigner le code CRPCEN de votre étude
            </Typography>
            <CrpcenInputCode length={5} onCodeChange={(code) => setOtp(code)} />
            <WarningTooltip title={otp?.length !== 5 ? "Veuillez entrer votre numéro CRPCEN" : ""}>
              <span>
                <LoadingButton
                  disabled={otp?.length !== 5}
                  loading={updateCrpcen.isPending}
                  onClick={() => updateCrpcen.mutate({ crpcen: otp })}
                >
                  Valider
                </LoadingButton>
              </span>
            </WarningTooltip>
          </Stack>
        </Card>
      )}
    </Box>
  );
}
