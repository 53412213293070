import React, { useCallback, useMemo, useState } from "react";

import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { typeToColor, typeToLabel } from "@/taskpane/utils/filiation";
import { PropertyNode, PropertyType } from "@/taskpane/types/filiation";
import CustomCheckbox from "@/taskpane/components/custom-checkbox/CustomCheckbox";
import TableValueCell from "@/taskpane/modules/folder/filiation/filiation-table/TableValueCell";
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { sortNumberOrString } from "@/taskpane/utils/filiation";

export interface FiliationTableProps {
  data: PropertyNode[];
  type: PropertyType;
  noDataMessage: string;
  selectedProperties: number[];
  onSelectProperty: (ids: number[], checked: boolean) => void;
  filters: {
    search: string;
    parcelleIds: number[];
    volumeIds: number[];
  };
  loading: boolean;
  colNum: number;
}

export default function FiliationTable({
  data,
  type,
  onSelectProperty,
  selectedProperties,
  noDataMessage,
  filters,
  loading,
  colNum,
}: FiliationTableProps) {
  const theme = useTheme();
  const [sort, setSort] = useState<"asc" | "desc">("asc");

  const handleSort = useCallback(() => {
    setSort((prev) => {
      if (prev === "asc") {
        return "desc";
      } else {
        return "asc";
      }
    });
  }, [sort, setSort]);

  const filteredData = useMemo(() => {
    let filtered = data;

    if (filters.search) {
      filtered = filtered.filter(
        (node) =>
          node.value.toLowerCase().includes(filters.search.toLowerCase()) ||
          node.parents.some((parent) => parent.value.toLowerCase().includes(filters.search.toLowerCase()))
      );
    }

    if (filters.parcelleIds.length > 0) {
      filtered = filtered.filter((node) => node.parents.some((parent) => filters.parcelleIds.includes(parent.id)));
    }

    if (filters.volumeIds.length > 0) {
      filtered = filtered.filter((node) => node.parents.some((parent) => filters.volumeIds.includes(parent.id)));
    }

    return filtered;
  }, [data, filters]);

  const filteredAndSortedData = useMemo(() => {
    return sortNumberOrString(filteredData, sort);
  }, [filteredData, sort]);

  return (
    <Stack height={1} pb={4} pt={1} sx={{ overflowY: "auto" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={5}>
                <CustomCheckbox
                  sx={{ p: 0 }}
                  color={typeToColor(type)}
                  checked={data.every((node) => selectedProperties.includes(node.id)) && data.length > 0}
                  onChange={(_, checked) =>
                    onSelectProperty(
                      data.map((node) => node.id),
                      checked
                    )
                  }
                />
              </TableCell>
              <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography fontSize={12} color={`${typeToColor(type)}.600`} fontWeight={600}>
                    {typeToLabel(type)}
                  </Typography>
                  {sort === "asc" ? (
                    <MdArrowDownward size={16} style={{ color: theme.palette[typeToColor(type) || "primary"].main }} />
                  ) : (
                    <MdArrowUpward size={16} style={{ color: theme.palette[typeToColor(type) || "primary"].main }} />
                  )}
                </Stack>
              </TableCell>
              {type === "lot" && (
                <TableCell>
                  <Typography fontStyle="italic" fontSize={12} color={`${typeToColor("volume")}.600`}>
                    Volume attaché
                  </Typography>
                </TableCell>
              )}
              {(type === "lot" || type === "volume") && (
                <TableCell>
                  <Typography fontStyle="italic" fontSize={12} color={`${typeToColor("cadastre")}.600`}>
                    Parcelle attachée
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              filteredAndSortedData.length > 0 &&
              filteredAndSortedData
                // .sort((obj1, obj2) => parseInt(obj1.value) - parseInt(obj2.value))
                .map((node) => (
                  <TableRow key={node.id}>
                    <TableCell width={5}>
                      <CustomCheckbox
                        sx={{ py: 0.2, px: 0 }}
                        color={typeToColor(type)}
                        checked={selectedProperties.includes(node.id)} // As the selectedProperties is updated with filiation element that are chosen, it carry the actual checked the value.
                        onChange={(_, checked) => onSelectProperty([node.id], checked)}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={12} color={`${typeToColor(type)}.600`} fontWeight={600}>
                        {node.value}
                      </Typography>
                    </TableCell>
                    {type === "lot" && <TableValueCell node={node} color={typeToColor("volume")} type="volume" />}
                    {(type === "lot" || type === "volume") && (
                      <TableValueCell node={node} color={typeToColor("cadastre")} type="cadastre" />
                    )}
                  </TableRow>
                ))}
            {loading && <TableRowsLoader rowsNum={6} colNum={colNum} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && filteredData.length === 0 && (
        <Typography fontSize={12} color="gray" textAlign="center" mt={2}>
          {noDataMessage}
        </Typography>
      )}
    </Stack>
  );
}

const TableRowsLoader = ({ rowsNum = 5, colNum = 3 }) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <>
        {colNum > 0 &&
          [...Array(colNum)].map((_, index) => (
            <TableCell key={`col-${index}`}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
      </>
    </TableRow>
  ));
};
