import React from "react";

import { Navigate, useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
  let error = useRouteError() as Error;

  console.error(error);

  return <Navigate to={window.location.hash === "#/folders" ? `${window.location.origin}${window.location.pathname}?${window.location.search}#/login` : `${window.location.origin}${window.location.pathname}?${window.location.search}#/folders`} replace={true} />
}
