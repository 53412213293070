import { FormControl, InputLabel, Select, SelectProps, useTheme } from "@mui/material";
import React from "react";

export interface StyledSelectProps {
  borderColor?: string;
}

export default function StyledSelect({
  borderColor = "rgba(145, 158, 171, 0.20)",
  ...props
}: StyledSelectProps & SelectProps) {
  const theme = useTheme();

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          fontSize: 11,
          color: theme.palette.text.secondary,
          "&:not(.MuiInputLabel-shrink)": {
            top: -12,
          },
          "&.Mui-focused": {
            top: 5,
          },
        }}
      >
        {props.label}
      </InputLabel>
      <Select
        sx={{
          fontSize: 11,
          minWidth: 85,
          color: "grey",
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${borderColor}`,
            borderRadius: 2,
          },
          "& .MuiSelect-select": {
            paddingRight: 4,
            paddingLeft: 2,
            paddingTop: 0.5,
            paddingBottom: 0.5,
          },
        }}
        {...props}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}
