import React, { useCallback, useEffect, useMemo } from "react";

import { Box, Grid, Paper, Typography, IconButton, Stack } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { FiliationList } from "@/diagramme/components";

import { useParams } from "react-router-dom";
import { usePublicFiliationData } from "@/diagramme/hooks";

import { PANEL_HEADER_TITLE_HEIGHT, WHITE_DARK, DEFAULT_ELEVATION, BLUE_BYLAW, PANEL_WIDTH } from "@/diagramme/config";

/**
 * FiliationPanel component
 *
 * @param {Object} props - Component props
 * @param {Array} props.selectedProperties - Array of selected properties
 * @param {Function} props.setSelectedProperties - Function to set selected properties
 * @param {Array} props.filteredFiliationDataIds - Array of filtered filiation data IDs
 * @param {boolean} props.parentLoading - Loading state of the parent component
 * @returns {JSX.Element} The rendered component
 */
export function FiliationPanel({
  filteredFiliationDataIds,
  handleDrawerClose,
  parentLoading,
  selectedProperties,
  setSelectedProperties,
}: any) {
  /**
   * Extract parameters from the URL
   * @type {Object}
   * @property {string} client_id - Client ID from URL
   * @property {string} dossier_id - Dossier ID from URL
   * @property {string} sous_dossier_id - Sous Dossier ID from URL
   * @property {string} flow_id - Flow ID from URL
   */
  const { client_id, dossier_id, sous_dossier_id, flow_id } = useParams();

  /**
   * Fetch public filiation data using custom hook
   * @param {string} client_id - Client ID
   * @param {string} dossier_id - Dossier ID
   * @param {string} sous_dossier_id - Sous Dossier ID
   * @param {string} flow_id - Flow ID
   * @param {Array} filteredFiliationDataIds - Array of filtered filiation data IDs
   * @returns {Object} Filiation data and loading state
   */
  const { parcellesWithNoChildren, lots, volumesWithNoChildren, isLoading } = usePublicFiliationData(
    client_id || "",
    dossier_id || "",
    sous_dossier_id || "",
    flow_id || "",
    filteredFiliationDataIds
  );

  /**
   * @description Callback to handle property selection. Not use for now
   *
   * @param {number[]} ids - IDs of selected properties
   * @param {boolean} checked - Whether the properties are being selected or deselected
   */
  const handleSelectProperty = useCallback(
    (_ids: number[], checked: boolean) => {
      if (checked) {
        // setSelectedProperties((prev: any) => [...prev, ...ids]);
      } else {
        // setSelectedProperties((prev: any) => prev.filter((id: any) => !ids.includes(id)));
      }
    },
    [selectedProperties]
  );

  /**
   * Side effect that updates the selected properties of each lot when the user manually selects a lot.
   * This effect is responsible for updating the checkbox in render to represent a user choice and
   * adding it to already chosen lots.
   *
   * @param {number[]} ids - IDs of selected lots
   * @param {boolean} checked - Whether the properties are being selected or deselected
   */
  useEffect(() => {
    // Effect logic here
  }, [selectedProperties, setSelectedProperties]);

  /**
   * Side effect that updates the selected properties of each lots when the user manually select a lot.
   * This effect is responsible to update the checkbox in render to representing a user choice and
   * adding it to already chosen lots.
   * @param {number[]} ids - IDs of selected lots
   * @param {boolean} checked - Whether the properties are being selected or deselected
   */
  useEffect(() => {
    if (lots && lots?.length && lots.length > 0) {
      const chosenLots = lots
        .map((chosenLot) => {
          if (chosenLot.chosen === true) {
            return chosenLot.id;
          } else {
            return null;
          }
        })
        .filter((chosenLot) => chosenLot !== null);
      setSelectedProperties((prev: any) => [...prev, ...chosenLots]);
    }
  }, [lots]);

  /**
   * Side effect that track changes of the parcelle received in the filiation
   * from the API.
   *
   * This effect is responsible to update the selected parcelles with parcelles
   * from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what parcelles are already chose before making
   * the filiation choice.
   *
   * @dependency {obj[]} parcellesWithNoChildren - Parcelles received from the API
   * in the filiation.
   */
  useEffect(() => {
    if (parcellesWithNoChildren && parcellesWithNoChildren?.length && parcellesWithNoChildren.length > 0) {
      const chosenParcellesWithNoChildren = parcellesWithNoChildren
        .map((chosenParcelle: any) => {
          if (chosenParcelle.chosen === true) {
            return chosenParcelle.id;
          } else {
            return null;
          }
        })
        .filter((chosenParcelle: any) => chosenParcelle !== null);
      setSelectedProperties((prev: any) => [...prev, ...chosenParcellesWithNoChildren]);
    }
  }, [parcellesWithNoChildren]);

  /**
   * Side effect that track changes of the volumes received in the filiation
   * from the API.
   *
   * This effect is responsible to update the selected volumes with volumes
   * from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what volumes are already chose before making
   * the filiation choice.
   *
   * @dependency {obj[]} volumesWithNoChildren - volumes received from the API
   * in the filiation.
   */
  useEffect(() => {
    if (volumesWithNoChildren && volumesWithNoChildren?.length && volumesWithNoChildren.length > 0) {
      const chosenVolumes = volumesWithNoChildren
        .map((chosenVolume: any) => {
          if (chosenVolume.chosen === true) {
            return chosenVolume.id;
          } else {
            return null;
          }
        })
        .filter((chosenVolume: any) => chosenVolume !== null);
      setSelectedProperties((prev: any) => [...prev, ...chosenVolumes]);
    }
  }, [volumesWithNoChildren]);

  const filteredData = useMemo(() => {
    if (filteredFiliationDataIds === undefined) {
      return {
        filteredLots: [],
        filteredParcelles: [],
        filteredVolumes: [],
      };
    } else {
      return {
        filteredLots: lots,
        filteredParcelles: parcellesWithNoChildren,
        filteredVolumes: volumesWithNoChildren,
      };
    }
  }, [lots, parcellesWithNoChildren, volumesWithNoChildren]);

  const loading = useMemo(() => {
    return isLoading || parentLoading || filteredFiliationDataIds === undefined;
  }, [isLoading, parentLoading, filteredFiliationDataIds]);

  return (
    <Paper
      variant="elevation"
      elevation={DEFAULT_ELEVATION}
      sx={{
        p: 0,
        borderRadius: 0,
        height: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: `${WHITE_DARK}`,
          display: "flex",
          height: `${PANEL_HEADER_TITLE_HEIGHT}px`,
          justifyContent: "center",
          p: 1,
          position: "sticky",
          top: 0,
          width: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ color: BLUE_BYLAW, fontWeight: 600 }}>{`Choix de filiation`}</Typography>
        <IconButton onClick={handleDrawerClose} size="medium" sx={{ position: "absolute", right: 0, top: "4px" }}>
          <ExpandCircleDownIcon color="primary" fontSize="medium" sx={{ rotate: "90deg" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: `calc(100% - ${PANEL_HEADER_TITLE_HEIGHT}px)`,
          width: 1,
          opacity: loading ? 0.5 : 1,
          p: 2,
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <Box sx={{ height: 1, display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
          <Box>
            <FiliationList
              colNum={0}
              color="success"
              data={filteredData.filteredParcelles}
              indicator={""}
              loading={loading}
              noDataMessage="Aucune parcelle sélectionnée"
              onSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              title="Parcelles sélectionnées :"
              type="cadastre"
            />
          </Box>
          <Box>
            <FiliationList
              colNum={2}
              color="warning"
              data={filteredData.filteredVolumes}
              indicator={""}
              loading={loading}
              noDataMessage="Aucun volume sélectionné"
              onSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              title="Volumes sélectionnés :"
              type="volume"
            />
          </Box>
          <Box>
            <FiliationList
              colNum={3}
              color="primary"
              data={filteredData.filteredLots}
              indicator=""
              loading={loading}
              noDataMessage="Aucun lot à affiché"
              onSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              title="Lots sélectionnés :"
              type="lot"
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
