import React from "react";

import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function SnackbarCloseIcon({ handleClose }: { handleClose: () => void }) {
    return (
        <IconButton aria-label="close" color="default" onClick={handleClose} sx={{ position: "absolute", top: -2.5, right: -2.5, color: "#637381" }}>
            <CloseIcon />
        </IconButton>
    )
}