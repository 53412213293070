import React from "react";

import { Handle, Position } from "@xyflow/react";

import Grid from "@mui/material/Unstable_Grid2";
import { Card, Chip, Stack, Typography } from "@mui/material";

import { NODE_COLOR_MAP } from "@/diagramme/config";

/**
 * GroupNode component
 * @description Component to render a group node in the React Flow diagram.
 * @param {NodeProps<ReactflowNodeData>} props - Component props
 * @returns {JSX.Element} The rendered component
 */
export function GroupedNode(props: any) {
  const { data } = props;

  /**
   * @description Determines the main color of the node based on its type.
   */
  const mainColor =
    data.type === "commune"
      ? NODE_COLOR_MAP.commune.main
      : data.type === "cadastre"
        ? NODE_COLOR_MAP.cadastre.main
        : data.type === "lot"
          ? NODE_COLOR_MAP.lot.main
          : NODE_COLOR_MAP.volume.main;

  return (
    <>
      <Typography
        variant="caption"
        sx={{ fontSize: "10px", color: mainColor, position: "absolute", top: "-16px", left: 0 }}
      >
        {data.type}
      </Typography>
      <Handle type="target" position={Position.Top} />
      {/* Node content */}
      <Card
        elevation={0}
        style={{ maxHeight: "192px", overflowY: "auto", padding: "8px", background: "transparent", border: "none" }}
      >
        {Array.isArray(data.value) && data.value.length > 3 ? (
          <Grid container spacing={0.5}>
            {data.value.map((node: any, index: number) => {
              return (
                <Grid key={`node-group-item-${index}-${node}`} xs={6}>
                  <Chip
                    clickable
                    variant="outlined"
                    size="small"
                    label={node}
                    sx={{
                      fontSize: "10px",
                      width: 1,
                      color: mainColor,
                      border: `1px solid ${mainColor}`,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent={data.value?.length === 1 ? "center" : "space-between"}
            alignItems="center"
            spacing={0.5}
          >
            {Array.isArray(data.value) &&
              data.value.map((node: any, index: number) => {
                return (
                  <Chip
                    clickable
                    variant="outlined"
                    key={`node-group-item-${index}-${node}`}
                    size="small"
                    label={node}
                    sx={{
                      fontSize: "10px",
                      width: 1,
                      color: mainColor,
                      border: `1px solid ${mainColor}`,
                    }}
                  />
                );
              })}
          </Stack>
        )}
      </Card>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
}

GroupedNode.displayName = "GroupNode";
