import React, { useCallback } from "react";

import { Handle, Position } from "@xyflow/react";

import { Card, Chip, Typography } from "@mui/material";

import { NODE_COLOR_MAP } from "@/diagramme/config";

/**
 * BaseNode component
 * @description Component to render a node in the React Flow diagram.
 * @param {NodeProps<ReactflowNodeData>} props - Component props
 * @returns {JSX.Element} The rendered component
 */
export function BaseNode({ data }: any) {
  /**
   * @description Determines the main color of the node based on its type.
   */
  const mainColor =
    data.type === "commune"
      ? NODE_COLOR_MAP.commune.main
      : data.type === "cadastre"
        ? NODE_COLOR_MAP.cadastre.main
        : data.type === "lot"
          ? NODE_COLOR_MAP.lot.main
          : NODE_COLOR_MAP.volume.main;
  return (
    <>
      <Typography variant="caption" sx={{ fontSize: "10px", color: mainColor, position: "absolute", top: "-16px" }}>
        {data.type}
      </Typography>
      <Handle type="target" position={Position.Top} />
       {/* Node content */}
	   <Card
        elevation={0}
        style={{ maxHeight: "192px", overflowY: "auto", padding: "8px", background: "transparent", border: "none" }}
      >
        <Chip
          clickable
          variant="outlined"
          size="small"
          label={data.value}
          sx={{
            fontSize: "10px",
            width: 1,
            color: mainColor,
            border: `1px solid ${mainColor}`,
          }}
        />
      </Card>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
}

BaseNode.displayName = "BaseNode";
