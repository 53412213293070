import { useTheme } from "@mui/material";
import React from "react";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { IoDocumentOutline } from "react-icons/io5";
import { PiMicrosoftExcelLogoFill, PiMicrosoftWordLogoFill } from "react-icons/pi";

const styles = {
  flexShrink: 0,
};

export default function FileIconType({ fileName }: { fileName: string }) {
  const extension = fileName.split(".").pop()?.toLocaleLowerCase();
  const theme = useTheme();

  switch (extension) {
    case "pdf":
      return <BsFileEarmarkPdfFill style={styles} color="#E94848" />;
    case "xlsx":
      return <PiMicrosoftExcelLogoFill style={styles} color="#107C41" />;
    case "docx":
      return <PiMicrosoftWordLogoFill style={styles} color="#185ABD" />;
    default:
      return <IoDocumentOutline style={styles} color={theme.palette.grey[400]} />;
  }
}
