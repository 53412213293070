import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export interface LoadingBarProps {
  text: string;
  variant?: "buffer" | "determinate" | "indeterminate" | "query";
  progress?: number | null;
}

export default function LoadingBar({ text, variant = "indeterminate", progress = null }: LoadingBarProps) {
  const theme = useTheme();

  return (
    <Stack spacing={0.5} alignItems="center" width={1} px={2} py={1}>
      <Stack width={1} alignItems="center" direction="row">
        <LinearProgress
          color="success"
          variant={variant}
          sx={{ width: "100%" }}
          {...(progress !== null ? { value: progress } : {})}
        />
        {progress || progress === 0 ? (
          <Typography fontSize={13} sx={{ color: "text.secondary", ml: 1 }}>{`${Math.round(progress)}%`}</Typography>
        ) : null}
      </Stack>
      <Typography fontSize={13} color={theme.palette.success.main}>
        {text}
      </Typography>
    </Stack>
  );
}
