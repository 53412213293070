import {
	MarkerType,
} from "@xyflow/react";

export const proOptions = {
	account: "paid-pro",
	hideAttribution: true,
};

export const defaultEdgeOptions = {
	type: "step" as "default" | "straight" | "step" | "smoothstep" | "simplebezier",
	markerEnd: { type: MarkerType.ArrowClosed },
	pathOptions: { offset: 5 },
};

export const NODE_COLOR_MAP = {
	commune: {
		main: "#767676",
		bg: "#e3e3e3",
	},
	cadastre: {
		main: "#22c55e",
		bg: "#e8f9ee",
	},
	volume: {
		main: "#ff8e09",
		bg: "#fff3e6",
	},
	lot: {
		main: "#1b489e",
		bg: "#e8ecf5",
	},
};

export const BLUE_BYLAW = "#1B269E"
export const BLUE_BYLAW_LIGHT = "#d8dcf0"
export const BORDER_RADIUS_MUI = 2;
export const DEFAULT_BLUR = 10;
export const DEFAULT_ELEVATION = 24;
export const DEFAULT_PADDING = 8;
export const DEFAULT_PADDING_MUI = 2;
export const DIAGRAMME_ALGORITHM = "dagre";
export const LOGO_BOX_WIDTH = 192;
export const LOGO_WIDTH = 134;
export const PANEL_HEADER_TITLE_HEIGHT = 48;
export const PANEL_WIDTH = 300;
export const TOOLBAR_HEIGHT = 64;
export const WHITE = "#fff"
export const WHITE_DARK = "#ebebeb"