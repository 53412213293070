import React, { useState } from "react";

import { Box, Card, Stack, Typography } from "@mui/material";
import { FiliationTable } from "@/diagramme/components";

import type { PropertyNode, PropertyType } from "@/taskpane/types/filiation";

export interface FiliationListProps {
  colNum: number;
  color: "success" | "warning" | "primary";
  data: PropertyNode[];
  indicator: string;
  loading: boolean;
  noDataMessage: string;
  onSelectProperty: (ids: number[], checked: boolean) => void;
  selectedProperties: number[];
  title: string;
  type: PropertyType;
}

/**
 * FiliationList component
 * @description - Component to display filiation data in grouped by type list: parcelles, volumes, lots.
 * This component is used in the filiation panel of the diagram.
 * It render only one type of filiation object.
 * Here we haven't reused the same as in the addin because style variations were to differents and need for
 * this component can evolve differently.
 * @param {FiliationListProps} props - Component props represented by the interface FiliationListProps.
 * @returns {JSX.Element} The rendered component
 */
export function FiliationList({
  colNum,
  color,
  data,
  indicator,
  loading,
  noDataMessage,
  title,
  type,
}: FiliationListProps) {
  /**
   * @description - State to manage search input in filiation list that have one like lots and volumes.
   * It allow to search for a specific elements in filiation objects.
   */
  const [search, setSearch] = useState("");

  /**
   * @description - State to manage the selected parcelles.
   *
   * By selected, we refer to the default filiation objects that have a chosen property to true and
   * to the manually selected filiation objects selected by the user.
   *
   * For now, we don't allow user to update his choice by validating selection to the API.
   *
   * Selection act more like a selection of objects on which we want to apply read actions like
   * filtering, searching, etc.
   */
  const [selectedParcelles, setSelectedParcelles] = useState<number[]>([]);

  /**
   * @description - State to manage the selected volumes.
   *
   * By selected, we refer to the default filiation objects that have a chosen property to true and
   * to the manually selected filiation objects selected by the user.
   *
   * For now, we don't allow user to update his choice by validating selection to the API.
   *
   * Selection act more like a selection of objects on which we want to apply read actions like
   * filtering, searching, etc.
   */
  const [selectedVolumes, setSelectedVolumes] = useState<number[]>([]);

  return (
    <Stack height={1}>
      <Box>
        <Typography
          fontSize={13}
          color={`${color}.600`}
          fontWeight={600}
          sx={{ ...(indicator === "" ? { mb: 0.5 } : {}) }}
        >
          {title}
        </Typography>
        <Typography fontSize={10} color={`${color}.600`}>
          {indicator}
        </Typography>
      </Box>
      <Card sx={{ flexGrow: 1, padding: 0}}>
        <FiliationTable
          data={data}
          type={type}
          noDataMessage={noDataMessage}
          loading={loading}
          colNum={colNum}
          filters={{
            search,
            parcelleIds: selectedParcelles,
            volumeIds: selectedVolumes,
          }}
        />
      </Card>
    </Stack>
  );
}
