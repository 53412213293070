import { Position } from '@xyflow/react';
import { Direction } from '@/diagramme/data/algorithms';

import { NODE_COLOR_MAP } from "@/diagramme/config";

import type { Edge, Node } from '@xyflow/react';
import type { FiliationTree } from "@/diagramme/types";



export function getSourceHandlePosition(direction: Direction) {
	switch (direction) {
		case 'TB':
			return Position.Bottom;
		case 'BT':
			return Position.Top;
		case 'LR':
			return Position.Right;
		case 'RL':
			return Position.Left;
	}
}

export function getTargetHandlePosition(direction: Direction) {
	switch (direction) {
		case 'TB':
			return Position.Top;
		case 'BT':
			return Position.Bottom;
		case 'LR':
			return Position.Left;
		case 'RL':
			return Position.Right;
	}
}

export function getId() {
	return `${Date.now()}`;
}

export const groupConsecutiveNumbers = (arr: any[]) => {
	// First, sort the array to ensure numbers are in order
	arr.sort((a, b) => a - b);

	const result = [];
	let tempArray = [];

	for (let i = 0; i < arr.length; i++) {
		// Start a new group if tempArray is empty
		if (tempArray.length === 0) {
			tempArray.push(arr[i]);
		}
		else if (arr[i] === tempArray[tempArray.length - 1] + 1) {
			tempArray.push(arr[i]);
		}
		else {
			// If not consecutive, push the current group to result and start a new group
			result.push(tempArray);
			tempArray = [arr[i]];
		}
	}

	// Don't forget to push the last group to the result
	if (tempArray.length > 0) {
		result.push(tempArray);
	}

	return result;
}

export const groupConsecutiveWords = (arr: any[]) => {
	let mappedArr = arr.flat(Infinity).filter(word => typeof word === 'string').map(word => {
		let match = word.match(/\d+/);
		let num = match ? parseInt(match[0], 10) : NaN;

		return { word, num };
	});

	mappedArr = mappedArr.filter(item => !isNaN(item.num));


	const result = [];
	let tempArray: any[] = [];

	for (let i = 0; i < mappedArr.length; i++) {
		if (tempArray.length === 0) {
			tempArray.push(mappedArr[i].word);
		}
		else if (mappedArr[i].num === mappedArr[i - 1].num + 1) {
			tempArray.push(mappedArr[i].word);
		}
		else {
			result.push(tempArray);
			tempArray = [mappedArr[i].word];
		}
	}

	// Add the last group to the result
	if (tempArray.length > 0) {
		result.push(tempArray);
	}

	return result;
}

export function extractNumbers(str: string) {
	if (typeof str !== 'string') {
		return '';
	}
	const match = str.match(/\d+/);
	if (match) {
		return match[0];
	} else {
		return '';
	}
}

export const workflow2reactflow = (
	data: FiliationTree
): { nodes: Node[]; edges: Edge[] } => {
	const new_nodes: any & Node[] = [];
	const groupedNodes: any & { [key: number]: Node[] } = {};

	// Group nodes by their parent_id if they have no children
	for (const node of data.nodes) {
		if (node.children_ids?.length === 0) {
			const parentId = node.parents_ids[0];
			if (!groupedNodes[parentId]) {
				groupedNodes[parentId] = [];
			}
			groupedNodes[parentId].push(node) as any;
		} else {
			new_nodes.push(node);
		}
	}

	// Merge grouped nodes into new_nodes
	for (const parentId in groupedNodes) {
		const group = groupedNodes[parentId];
		if (group.length > 0) {
			const firstNode = group[0];
			const mergedNode: any & Node = {
				...firstNode,
				value: group.map((n: any) => n.value),
			};

			new_nodes.push(mergedNode);
		}
	}

	// Update children_ids to only include existing nodes
	const existingNodeIds = new Set(new_nodes.map(node => node.id));
	new_nodes.forEach(node => {
		node.children_ids = node.children_ids.filter(id => existingNodeIds.has(id));
	});

	// Sort nodes by id for clarity (optional)
	new_nodes.sort((a: any, b: any) => a.id - b.id);

	data.nodes = new_nodes as any;

	// Filter nodes appearing multiple times
	const seenNodes = new Set();
	data.nodes = data.nodes.filter((node) => {
		const duplicate = seenNodes.has(node.id);
		seenNodes.add(node.id);
		return !duplicate;
	});

	const nodes: any & Node[] = data.nodes.map((node) => {
		return {
			id: node.id.toString(),
			data: {
				...node,
				value: node.value,
			},
			position: { x: 0, y: 0 }, // Initial position, will be updated by layout
			style: {},
			type: Array.isArray(node.value) && node.value.length ? 'group' : 'base',
		};
	});

	const edges: Edge[] = data.nodes.flatMap((node) =>
		node.children_ids.map((childId) => ({
			id: `${node.id}->${childId}`,
			source: node.id.toString(),
			target: childId.toString(),
			style: {},
		}))
	);

	return { nodes, edges };
};

export function groupNodes(nodes: Node[]) {
	if (!nodes) {
		return [];
	}

	// Check if nodes are different from previous nodes and if they are not empty
	if (Array.isArray(nodes) && nodes.length > 0) {
		return nodes.map((kNode) => {
			const dataValue = kNode?.data?.value;

			// Check if dataValue is already formatted by grouping to avoid re-formatting an already formatted string
			if (
				dataValue &&
				Array.isArray(dataValue) &&
				dataValue.length > 0 &&
				dataValue.some((item) => typeof item === 'string' && item.includes(' à '))
			) {
				let defaultNodeWidth = 128;

				switch (dataValue.length) {
					case 1:
						defaultNodeWidth = 96;
						break;
					case 2:
						defaultNodeWidth = 160;
						break;
					case 3:
						defaultNodeWidth = 272;
						break;
					default:
						defaultNodeWidth = 272;
						break;
				}

				// Return the formatted node
				return {
					...kNode,
					type: 'group',
					data: {
						...kNode.data,
						value: [...dataValue],
					},
					style: {
						...(kNode.data.type === 'commune'
							? {
								width: defaultNodeWidth,
								background: NODE_COLOR_MAP.commune.bg,
								border: `1px solid ${NODE_COLOR_MAP.commune.main}`,
							}
							: kNode.data.type === 'cadastre'
								? {
									width: defaultNodeWidth,
									background: NODE_COLOR_MAP.cadastre.bg,
									border: `1px solid ${NODE_COLOR_MAP.cadastre.main}`,
								}
								: kNode.data.type === 'lot'
									? {
										width: defaultNodeWidth,
										background: NODE_COLOR_MAP.lot.bg,
										border: `1px solid ${NODE_COLOR_MAP.lot.main}`,
									}
									: {
										width: defaultNodeWidth,
										background: NODE_COLOR_MAP.volume.bg,
										border: `1px solid ${NODE_COLOR_MAP.volume.main}`,
									}),
					},
				};
			} else if (dataValue && Array.isArray(dataValue) && dataValue.length > 0) {
				// Flatten the array and parse the values to integers if possible
				const baseDataValue = dataValue.flat().map((item) => {
					const parsedItem = parseInt(item);
					return isNaN(parsedItem) ? item : parsedItem;
				});

				// Filter non-number values and sort them
				const nonNumberValues = baseDataValue.filter((item) => typeof item !== 'number').sort();

				// Group consecutive non-number values
				const groupedNonNumberValues = groupConsecutiveWords(nonNumberValues).map((arrOrString) => {
					if (Array.isArray(arrOrString) && arrOrString.length > 1) {
						return `${arrOrString[0]} à ${extractNumbers(arrOrString[arrOrString.length - 1])}`;
					} else if (Array.isArray(arrOrString) && arrOrString.length === 1) {
						return arrOrString[0];
					} else {
						return arrOrString;
					}
				});

				// Filter number values and sort them
				const numberValues = baseDataValue
					.filter((item) => typeof item === 'number')
					.filter((value, index, self) => self.indexOf(value) === index);

				// Group consecutive numbers
				const arrOfConsecutiveNumbers = groupConsecutiveNumbers(numberValues).map((arrOrNumber: any[] | number) => {
					if (Array.isArray(arrOrNumber)) {
						return arrOrNumber.map((num) => num.toString());
					} else {
						return arrOrNumber.toString();
					}
				});

				// Group consecutive numbers into ranges
				const arrOfGroupedNumbers = arrOfConsecutiveNumbers.map((arrOrString) => {
					if (Array.isArray(arrOrString) && arrOrString.length > 1) {
						return `${arrOrString[0]} à ${arrOrString[arrOrString.length - 1]}`;
					} else {
						return arrOrString[0];
					}
				});

				// Merge grouped numbers and non-number values
				const formattedDataValue = [...arrOfGroupedNumbers, ...groupedNonNumberValues];

				let defaultNodeWidth = 128;

				switch (formattedDataValue.length) {
					case 1:
						defaultNodeWidth = 96;
						break;
					case 2:
						defaultNodeWidth = 160;
						break;
					case 3:
						defaultNodeWidth = 272;
						break;
					default:
						defaultNodeWidth = 272;
						break;
				}

				// Check if formattedDataValue is actually a single string
				const isSingleValue = formattedDataValue.length === 1;

				// Return the formatted node
				return {
					...kNode,
					type: isSingleValue ? 'base' : 'group',
					data: {
						...kNode.data,
						value: isSingleValue ? formattedDataValue[0] : [...formattedDataValue],
					},
					style: {
						...(kNode.data.type === 'commune'
							? {
								width: defaultNodeWidth,
								background: NODE_COLOR_MAP.commune.bg,
								border: `1px solid ${NODE_COLOR_MAP.commune.main}`,
							}
							: kNode.data.type === 'cadastre'
								? {
									width: defaultNodeWidth,
									background: NODE_COLOR_MAP.cadastre.bg,
									border: `1px solid ${NODE_COLOR_MAP.cadastre.main}`,
								}
								: kNode.data.type === 'lot'
									? {
										width: defaultNodeWidth,
										background: NODE_COLOR_MAP.lot.bg,
										border: `1px solid ${NODE_COLOR_MAP.lot.main}`,
									}
									: {
										width: defaultNodeWidth,
										background: NODE_COLOR_MAP.volume.bg,
										border: `1px solid ${NODE_COLOR_MAP.volume.main}`,
									}),
					},
				};
			} else {
				// If dataValue is not an array or is empty, return the node as is
				const formattedDataValue = dataValue;
				const defaultNodeWidth = 96;

				// Return the formatted node
				return {
					...kNode,
					data: { ...kNode.data, value: formattedDataValue },
					style: {
						width: kNode.data.type === 'commune' ? 196 : defaultNodeWidth,
						...(kNode.data.type === 'commune'
							? {
								background: NODE_COLOR_MAP.commune.bg,
								border: `1px solid ${NODE_COLOR_MAP.commune.main}`,
							}
							: kNode.data.type === 'cadastre'
								? {
									background: NODE_COLOR_MAP.cadastre.bg,
									border: `1px solid ${NODE_COLOR_MAP.cadastre.main}`,
								}
								: kNode.data.type === 'lot'
									? {
										background: NODE_COLOR_MAP.lot.bg,
										border: `1px solid ${NODE_COLOR_MAP.lot.main}`,
									}
									: {
										background: NODE_COLOR_MAP.volume.bg,
										border: `1px solid ${NODE_COLOR_MAP.volume.main}`,
									}),
					},
				};
			}
		});
	} else {
		return [];
	}
}