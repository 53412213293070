export const NOTARY_SPACES = [
  {
    name: "espacenotarial",
    url: "https://espacenotarial.com",
  },
  {
    name: "admin.espacenotarial",
    url: "https://admin.espacenotarial.com",
  },
];
