import React from "react";

import { createHashRouter, Navigate } from "react-router-dom";

import { Layout } from "@/taskpane/components/layout";
import CrpcenPage from "@/taskpane/modules/crpcen/Crpcen";
import { LoginPage } from "@/taskpane/modules/auth";
import FoldersList from "@/taskpane/modules/folders/FoldersList";
import ErrorPage from "@/taskpane/components/error-page/ErrorPage";
import Filiation from "@/taskpane/modules/folder/filiation/Filiation";
import FolderNotarySpace from "@/taskpane/modules/folder/notary-space/FolderNotarySpace";
import FolderAttachmentsPage from "@/taskpane/modules/folder/attachment/FolderAttachmentsPage";
import FolderDragAndDropPage from "@/taskpane/modules/folder/drag-and-drop/FolderDragAndDropPage";
import FiliationDiagramme from "@/diagramme";
import { NonAuthRouteGuard, ProtectedRoute } from "@/taskpane/components/protected-route/ProtectedRoute";

export const router = createHashRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/filiation/:client_id/:dossier_id/:sous_dossier_id/:flow_id",
        errorElement: <ErrorPage />,
        element: (
          <NonAuthRouteGuard>
            <FiliationDiagramme /> {/* Page du diagramme de filiation */}
          </NonAuthRouteGuard>
        ),
      },
      {
        index: true,
        path: "/login",
        errorElement: <ErrorPage />,
        element: (
          <NonAuthRouteGuard>
            <LoginPage /> {/* Page de connexion */}
          </NonAuthRouteGuard>
        ),
      },
      {
        path: "/crpcen",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute ignoreCrpcen>
            <CrpcenPage /> {/* Page saisie CRPCEN */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/folders",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <FoldersList /> {/* Page liste des dossiers (accueil) */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/folder/:folderId/filiation/:subFolderId",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <Filiation /> {/* Page filiation cadastrale - Mail de filiation  */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/folder/:folderId/attachment",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <FolderAttachmentsPage /> {/* Page pieces-jointes - Mail avec piece-jointe */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/folder/:folderId/drag-and-drop",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <FolderDragAndDropPage /> {/* Page glissez-deposez */}
          </ProtectedRoute>
        ),
      },
      {
        path: "/folder/:folderId/notary-space",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <FolderNotarySpace /> {/* Page espace-notarial */}
          </ProtectedRoute>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/login" />,
      },
    ],
  },
]);
