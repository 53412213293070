import React, { useEffect } from "react";

import { useCrpcenInput } from "@/taskpane/hooks/crpcen";

export interface InputCodeProps {
  length?: number;
  onCodeChange: (code: string) => void;
}

export default function CrpcenInputCode({ length = 4, onCodeChange }: InputCodeProps) {
  const { handleChangeOtp, otp, setOtp } = useCrpcenInput(length, true);

  useEffect(() => {
    onCodeChange(otp?.join(""));
  }, [otp, onCodeChange]);

  return (
    <div className="otp-container">
      {otp?.map((data, index) => (
        <input
          className="otp-input"
          id={`input-${index}`}
          type="tel"
          pattern="[0-9]*"
          inputMode="numeric"
          name="otp"
          maxLength={1}
          key={index}
          value={data}
          onChange={(e) => handleChangeOtp(e.target, index)}
          onFocus={(e) => e.target.select()}
          onPaste={(e) => {
            const pastedData = e.clipboardData.getData("text/plain").split("");

            if (pastedData.length !== length) {
              return;
            }
            setOtp(pastedData);
            const lastInput = document.getElementById(`input-${length - 1}`);
            lastInput?.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              const previousInput = document.getElementById(`input-${index - 1}`) as HTMLInputElement;
              const currentInput = document.getElementById(`input-${index}`) as HTMLInputElement;
              if (currentInput && currentInput.value !== "") {
                currentInput.value = "";
              } else if (previousInput) {
                previousInput.focus();
              }
              setOtp(otp.map((v, j) => (j === index ? "" : v)));
            }
          }}
        />
      ))}
    </div>
  );
}
