import {
  testNotarySpaceConnection,
  getNotarySpaceConnectionStatus,
  associateDataroomToFolder,
} from "@/taskpane/services/notary-space";
import { MutationOptions, useMutation } from "@tanstack/react-query";

export const useNotarySpaceConnectionMutation = (
  opt?: MutationOptions<
    Awaited<ReturnType<typeof testNotarySpaceConnection>>,
    Error,
    Parameters<typeof testNotarySpaceConnection>[0]
  >
) => {
  return useMutation({
    ...opt,
    mutationFn: async (data) => {
      const testResponse = await testNotarySpaceConnection(data);

      let statusResponse = undefined;

      /*
       * Poll the connection status until it is no longer pending.
       */
      do {
        statusResponse = await getNotarySpaceConnectionStatus({
          folderId: data.folderId,
          connectionId: testResponse.connection_id,
        });
        if (statusResponse.espace_notarial_status === "connection_pending") {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } while (statusResponse.espace_notarial_status === "connection_pending");

      if (
        statusResponse.espace_notarial_status === "connection_fail" ||
        statusResponse.espace_notarial_status === "connection_fail_wrong_credentials"
      ) {
        throw new Error("Connection failed");
      }

      return statusResponse;
    },
  });
};

/**
 * Custom hook for associating a dataroom to a folder mutation.
 *
 * @param opt - Optional mutation options.
 * @returns The result of the mutation.
 */
export const useAssociateDataroomToFolderMutation = (
  opt?: MutationOptions<
    Awaited<ReturnType<typeof associateDataroomToFolder>>,
    Error,
    Parameters<typeof associateDataroomToFolder>[0]
  >
) => {
  return useMutation({
    ...opt,
    mutationFn: associateDataroomToFolder,
  });
};
