import React, { useEffect, useMemo } from "react";

import { useOtpInput } from "@/taskpane/hooks/otp";
import { useAutoFocus } from "@/taskpane/hooks/autofocus";

export interface InputCodeProps {
  length?: number;
  onCodeChange: (code: string) => void;
  handleAltConfirmOTP?: (otp: any) => void;
  defaultOtp: string;
  previousDefaultOtp: string;
}

export default function InputCode({
  length = 4,
  onCodeChange,
  handleAltConfirmOTP,
  defaultOtp,
  previousDefaultOtp,
}: InputCodeProps) {
  const { handleChangeOtp, otp, setOtp } = useOtpInput(length);
  const otpInputFirstSlotRef = useAutoFocus();

  useEffect(() => {
    if (otp) {
      onCodeChange(otp.join(""));
    } else {
      setOtp(Array(length).fill(""));
    }
  }, [otp, onCodeChange, setOtp]);

  useEffect(() => {
    if (defaultOtp !== previousDefaultOtp && defaultOtp && defaultOtp !== "") {
      setOtp(defaultOtp.split(""));
    }
  }, [defaultOtp]);

  const otpArray = useMemo(() => {
    if (otp === null) {
      if (defaultOtp && defaultOtp !== "") {
        return defaultOtp.split("");
      } else {
        return Array(length).fill("");
      }
    } else {
      return otp;
    }
  }, [defaultOtp, otp]);

  return (
    <div className="otp-container">
      {otpArray.map((data, index) => (
        <input
          className="otp-input"
          id={`input-${index}`}
          type="tel"
          pattern="[0-9]*"
          inputMode="numeric"
          name="otp"
          maxLength={1}
          key={index}
          value={data}
          onChange={(e) => handleChangeOtp(e.target, index)}
          onFocus={(e) => e.target.select()}
          onPaste={(e) => {
            const pastedData = e.clipboardData.getData("text/plain").split("");

            if (pastedData.length !== length) {
              return;
            }
            setOtp(pastedData);
            const lastInput = document.getElementById(`input-${length - 1}`);
            lastInput?.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              const previousInput = document.getElementById(`input-${index - 1}`) as HTMLInputElement;
              const currentInput = document.getElementById(`input-${index}`) as HTMLInputElement;
              if (currentInput && currentInput.value !== "") {
                currentInput.value = "";
              } else if (previousInput) {
                previousInput.focus();
              }
              if (otp) {
                setOtp(otp.map((v, j) => (j === index ? "" : v)));
              }
            }
            if (e.key === "Enter" && otp !== null && otp.every((num) => num !== "") && handleAltConfirmOTP) {
              handleAltConfirmOTP(otp);
            }
          }}
          {...(index === 0 && otp === null && defaultOtp === "" ? { ref: otpInputFirstSlotRef } : {})}
          {...(index === 4 && otp === null && defaultOtp && defaultOtp !== "" ? { ref: otpInputFirstSlotRef } : {})}
        />
      ))}
    </div>
  );
}
