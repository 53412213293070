import { Card, Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export interface DragAndDropZoneProps {
  className?: string;
  options?: DropzoneOptions;
  folded?: boolean;
}

export default function DragAndDrop({ options, className, folded }: DragAndDropZoneProps) {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone(options);
  const theme = useTheme();
  const bgColor = useMemo(() => {
    if (isDragAccept) return `${theme.palette.success.light}1A`;
    if (isDragReject) return `${theme.palette.error.light}1A`;
    return "transparent";
  }, [isDragAccept, isDragReject]);
  const color = useMemo(() => {
    if (isDragAccept) return theme.palette.success.dark;
    if (isDragReject) return theme.palette.error.dark;
    return theme.palette.text.primary;
  }, [isDragAccept, isDragReject]);

  return (
    <Card
      {...getRootProps()}
      sx={{
        p: 2,
        cursor: "pointer",
        boxShadow: "none",
        borderStyle: "dashed",
        backgroundColor: bgColor,
        borderRadius: 2,
      }}
      className={className}
    >
      <input {...getInputProps()} />
      {folded ? (
        <Typography fontSize={13} fontWeight={600} textAlign="center" color="grey">
          Vous pouvez continuer à déposer des fichiers ici
        </Typography>
      ) : (
        <Stack spacing={1} color={color} alignItems="center">
          <img src="/assets/upload-illustration.svg" width="70%" />
          <Typography fontSize={16} fontWeight={600} textAlign="center">
            Déposez les fichiers ici
          </Typography>
          <Typography fontSize={13} textAlign="center">
            ou <span style={{ color: theme.palette.primary.main }}>parcourir</span> vos dossiers
          </Typography>
        </Stack>
      )}
    </Card>
  );
}
