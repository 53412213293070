import { getUserAttributes } from "@/taskpane/services/auth";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useGetUserAttributesQuery = (
  opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserAttributes>>>>
) =>
  useQuery({
    ...opt,
    queryFn: getUserAttributes,
    queryKey: ["getUserAttributes"],
  });
