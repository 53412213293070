import { cognitoClient, getAuthTokens } from "@/taskpane/utils/client";
import { Tokens, UserData } from "@/taskpane/types/auth";
import { isAxiosError } from "axios";
import { handleError } from "../utils/errors";

const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

/**
 * Generates a random password following specific rules.
 * @returns {string} A 16-character password with at least one number, special character, uppercase and lowercase letter.
 */
function generateRandomPassword(): string {
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const special = "!@#$%^&*()_+-=[]{}|;:,.<>?";
  const allChars = lowercase + uppercase + numbers + special;

  let password = "";
  password += lowercase[Math.floor(Math.random() * lowercase.length)];
  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += special[Math.floor(Math.random() * special.length)];

  for (let i = password.length; i < 16; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  return password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
}

/**
 * Creates a new account in AWS Cognito.
 * @throws {Error} If there's a network error or other unexpected issue.
 */
export const createNewAccount = async (email: string) => {
  const body = {
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
      {
        Name: "custom:CRPCEN",
        Value: "None",
      },
    ],
    Password: generateRandomPassword(),
    Username: email,
    ClientId: COGNITO_CLIENT_ID,
  };

  try {
    const response = await cognitoClient.post("/", body, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.SignUp",
        "Content-Type": "application/x-amz-json-1.1",
      },
    });
    return { result: response.data, statusCode: response.status };
  } catch (error) {
    return {
      result: "Error",
      statusCode: isAxiosError(error) ? error.response?.status : 400,
    };
  }
};

/**
 * Initiates the authentication process with AWS Cognito.
 * @throws {Error} If there's a network error or other unexpected issue.
 */
export const initiateAuth = async (email: string): Promise<{ result: any; statusCode?: number }> => {
  const body = {
    AuthParameters: {
      USERNAME: email,
      CHALLENGE_NAME: "CUSTOM_CHALLENGE",
    },
    AuthFlow: "CUSTOM_AUTH",
    ClientId: COGNITO_CLIENT_ID,
  };

  try {
    const response = await cognitoClient.post("/", body, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
    });
    const result = await response.data;
    return { result, statusCode: response.status };
  } catch (error) {
    console.error("Error initiating auth:", error);
    if (isAxiosError(error) && error.response?.data?.__type === "UserNotFoundException") {
      await createNewAccount(email);
      return initiateAuth(email);
    }

    return {
      result: "Error",
      statusCode: isAxiosError(error) ? error.response?.status : 400,
    };
  }
};

/**
 * Initiates the authentication process with AWS Cognito.
 * @throws {Error} If there's a network error or other unexpected issue.
 */
export const getUserAttributes = async () => {
  const tokens = await getAuthTokens();
  if (!tokens) return null;
  const body = {
    AccessToken: tokens.AccessToken,
  };

  try {
    const response = await cognitoClient.post("/", body, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
        "Content-Type": "application/x-amz-json-1.1",
      },
    });
    const result = (await response.data) as {
      UserAttributes: {
        Name: string;
        Value: string;
      }[];
    };
    if (
      !["email", "email_verified", "custom:CRPCEN", "sub"].every((attr) =>
        result.UserAttributes.some(({ Name }) => Name === attr)
      )
    )
      return null;

    const crpcen = result.UserAttributes.find(({ Name }) => Name === "custom:CRPCEN")?.Value ?? null;
    const user: UserData = {
      email: result.UserAttributes.find(({ Name }) => Name === "email")?.Value ?? "",
      emailVerified: result.UserAttributes.find(({ Name }) => Name === "email_verified")?.Value === "true",
      crpcen: crpcen === "None" ? null : crpcen,
      username: result.UserAttributes.find(({ Name }) => Name === "sub")?.Value ?? "",
    };

    return user;
  } catch (error) {
    handleError(error, "Error getting user attributes:", "Accès refusé. Veuillez vous reconnecter.", () => {
      const loginURL = `${window.location.origin}${window.location.pathname}?${window.location.search}#/login`;
      window.location.href = loginURL;
      window.localStorage.clear();
    });
    return null;
  }
};

/**
 * Responds to an authentication challenge from AWS Cognito.
 * @throws {Error} If there's a network error or other unexpected issue.
 */
export const respondToAuthChallenge = async (email: string, code: string, session = "") => {
  const body = {
    ChallengeName: "CUSTOM_CHALLENGE",
    ChallengeResponses: {
      USERNAME: email,
      ANSWER: code,
    },
    ClientId: COGNITO_CLIENT_ID,
    Session: session,
  };

  try {
    const response = await cognitoClient.post("/", body, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.RespondToAuthChallenge",
        "Content-Type": "application/x-amz-json-1.1",
      },
    });
    return { result: response.data, statusCode: response.status };
  } catch (error) {
    console.error("Error responding to auth challenge:", error);
    return {
      result: "Error",
      statusCode: isAxiosError(error) ? error.response?.status : 400,
    };
  }
};

export const refreshAuthTokens = async (refreshToken: string): Promise<Omit<Tokens, "RefreshToken" | "ExpiresOn">> => {
  // const body = {
  //   ChallengeName: "CUSTOM_CHALLENGE",
  //   grant_type: "refresh_token",
  //   ClientId: COGNITO_CLIENT_ID,
  //   code: refreshToken,
  // };
  const body = {
    AuthFlow: "REFRESH_TOKEN",
    ClientId: COGNITO_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  const res = await cognitoClient.post("/", body, {
    headers: {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    },
  });
  return res.data.AuthenticationResult;
};

// Legacy code removed. If needed, refer to version control history.
