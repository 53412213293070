import React, { useEffect } from "react";

import {
  ConnectionLineType,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";

import { useControls } from "leva";
import { useAutoLayout } from "@/diagramme/hooks";

import { proOptions, defaultEdgeOptions } from "@/diagramme/config/reactflow";
import { kNodeTypes } from "@/diagramme/components/nodes";

import { workflow2reactflow, groupNodes } from "@/diagramme/utils";

import { DIAGRAMME_ALGORITHM } from "@/diagramme/config";

import type { Node as NodeType, Edge as EdgeType } from "@xyflow/react";
import type { LayoutOptions } from "@/diagramme/hooks/auto-layout";
import type { FiliationTree, FiliationEventWithFiliationStateGroupedByDate } from "@/diagramme/types";

import "@xyflow/react/dist/style.css";
import "@/diagramme/index.css";

const NODE_TYPES = kNodeTypes;

interface FlowProps {
  chosenFiliationData: FiliationTree;
  currentDate: string;
  filiationStatesByEvents: FiliationEventWithFiliationStateGroupedByDate[] | null;
  panelOpenState: boolean;
  previousChosenFiliationData: FiliationTree | null;
  previousCurrentDate: string;
}

/**
 * This example shows how you can automatically arrange your nodes after adding child nodes to your graph.
 */
export function Flow({
  chosenFiliationData,
  currentDate,
  filiationStatesByEvents,
  panelOpenState,
}: FlowProps) {
  /**
   * @description State hooks for managing nodes in the flow diagram.
   * Don't directly mutate these states, use the provided layoutReactflow function instead.
   *
   * @returns {Array} nodes - Array of nodes in the flow diagram.
   */
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeType>([]);

  /**
   * @description State hooks for managing edges in the flow diagram.
   * Don't directly mutate these states, use the provided layoutReactflow function instead.
   *
   * @returns {Array} nodes - Array of edges in the flow diagram.
   */
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeType>([]);

  const { fitView } = useReactFlow();

  // 👇 This hook is used to display a leva (https://github.com/pmndrs/leva) control panel for this example.
  // You can safely remove it, if you don't want to use it.
  const layoutOptions = useControls({
    algorithm: {
      value: DIAGRAMME_ALGORITHM as LayoutOptions["algorithm"],
      options: ["dagre", "d3-hierarchy", "elk"] as LayoutOptions["algorithm"][],
    },
    direction: {
      value: "TB" as LayoutOptions["direction"],
      options: {
        down: "TB",
        right: "LR",
        up: "BT",
        left: "RL",
      } as Record<string, LayoutOptions["direction"]>,
    },
    spacing: [50, 50],
  });

  /**
   * @description Effect reacting to currentDate change and triggering computation of the new filiation state for the current date.
   */
  useEffect(() => {
    if (filiationStatesByEvents && currentDate) {
      const filiationStateForCurrentDate = [...filiationStatesByEvents].find(
        (filiationStateByDate: FiliationEventWithFiliationStateGroupedByDate) =>
          filiationStateByDate.date === currentDate
      );

      if (filiationStateForCurrentDate && chosenFiliationData) {
        const { nodes, edges } = workflow2reactflow({
          nodes: chosenFiliationData?.originesByDate[`${currentDate}`]?.nodes,
          origines: chosenFiliationData?.origines,
        });

        const groupedNodes = groupNodes(nodes);

        setNodes([...groupedNodes]);
        setEdges([...edges]);
      }
    }
  }, [filiationStatesByEvents, chosenFiliationData, currentDate, setNodes, setEdges, groupNodes]);

  /**
   * @description Handles the computation of the layout once the elements or the direction changes
   */
  useAutoLayout(layoutOptions);

  /**
   * @description Every time our nodes change, this hook center the graph again.
   */
  useEffect(() => {
    fitView();
  }, [nodes, edges, fitView, panelOpenState]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={NODE_TYPES}
      connectionLineType={ConnectionLineType.SmoothStep}
      defaultEdgeOptions={defaultEdgeOptions}
      nodesDraggable={false}
      nodesConnectable={false}
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
      proOptions={proOptions}
      zoomOnDoubleClick={false}
      panOnDrag={false}      // Disable panning with dragging
      panOnScroll={false}
      zoomOnPinch={false}    // Disable zooming with pinch gesture
    >
      <MiniMap nodeStrokeWidth={3} />
    </ReactFlow>
  );
}
