import SubFolderAction from "@/taskpane/modules/folder/notary-space/steps/notary-space-subfolder/SubFolderAction";
import { useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import { ShieldType } from "@/taskpane/types/notary-space";
import { isEspaceNotarialStatus, mapping_status } from "@/taskpane/utils/notary-space";
import { StyledTooltip } from "@/taskpane/utils/tooltips";
import { SubFolder } from "@/taskpane/types/folder";
import { Card, CircularProgress, FormControlLabel, IconButton, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { GrRefresh } from "react-icons/gr";

export interface NotarySpaceSubfolderProps {
  folderId: string;
  selectedSubFolder: SubFolder | undefined;
  setSelectedSubFolder: (subFolder: SubFolder) => void;
  refreshConnection: (connection_id: string) => void;
}

export default function NotarySpaceSubfolder({
  folderId,
  selectedSubFolder,
  setSelectedSubFolder,
  refreshConnection,
}: NotarySpaceSubfolderProps) {
  const { data: subFolders, isFetching: subFoldersLoading } = useGetSubFoldersQuery(folderId || "");

  const getRefreshableIcon = useCallback(
    (sf: SubFolder) => {
      if (!isEspaceNotarialStatus(sf.espace_notarial_status)) {
        console.error(`Invalid espace_notarial_status: ${sf.espace_notarial_status}`);
        return null;
      }

      const status = mapping_status[sf.espace_notarial_status];
      const now = new Date();
      const timeSinceLastDownload = now.getTime() - sf.espace_notarial_last_download.getTime();

      if (status.new_connection_possible) {
        return null;
      }

      const isRecentDownload =
        (sf.espace_notarial_status === "download_success" || sf.espace_notarial_status === "refresh_success") &&
        timeSinceLastDownload <= 2;
      const iconDisabled = status.refresh_icon_disabled || isRecentDownload;
      const tooltipText = isRecentDownload
        ? "La dernière analyse de cette dataroom date d'il y a moins de 2 heures"
        : status.refresh_icon_tooltip;

      return (
        <StyledTooltip title={tooltipText} type={iconDisabled ? "disabled" : (status.shield_type as ShieldType)}>
          <span>
            <IconButton
              size="small"
              color={status.refresh_type === "green" ? "success" : "error"}
              onClick={() => {
                if (!iconDisabled) {
                  refreshConnection(sf.espace_notarial_connection_id);
                }
              }}
              disabled={iconDisabled}
            >
              <GrRefresh />
            </IconButton>
          </span>
        </StyledTooltip>
      );
    },
    [refreshConnection]
  );

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 500px)",
        overflowY: "auto",
      }}
    >
      {subFoldersLoading && (
        <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
          <CircularProgress />
        </Stack>
      )}
      {!subFoldersLoading &&
        subFolders?.map((sf) => (
          <Stack direction="row" alignItems="center" key={sf.sous_dossier_id} justifyContent="space-between">
            <FormControlLabel
              control={
                <SubFolderAction
                  sf={sf}
                  selectedSubFolder={selectedSubFolder}
                  setSelectedSubFolder={setSelectedSubFolder}
                />
              }
              label={sf.nature + " " + sf.name}
              slotProps={{ typography: { fontSize: 12 } }}
            />
            {getRefreshableIcon(sf)}
          </Stack>
        ))}
    </Card>
  );
}
