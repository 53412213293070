import CustomCheckbox from "@/taskpane/components/custom-checkbox/CustomCheckbox";
import FileIconType from "@/taskpane/components/file-icon-type/FileIconType";
import { UploadableFile } from "@/taskpane/types/file";
import { StyledTooltip } from "@/taskpane/utils/tooltips";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export interface CheckableFileProps {
  attachment: UploadableFile & { isPdf: boolean };
  selectedAttachments: (UploadableFile & { isPdf: boolean })[];
  setSelectedAttachments: (attachments: (UploadableFile & { isPdf: boolean })[]) => void;
  uploaded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  failed?: boolean;
  style?: React.CSSProperties;
}

export default function CheckableFile({
  uploaded,
  loading = false,
  failed = false,
  attachment,
  selectedAttachments,
  setSelectedAttachments,
  disabled = false,
  style = {},
}: CheckableFileProps) {
  const theme = useTheme();

  /**
   * Handles the click event for the checkable file component.
   * If the component is not disabled and the attachment is a PDF,
   * it toggles the selection of the attachment in the list of selected attachments.
   * @returns void
   */
  const handleClick = () => {
    if (!disabled && attachment.isPdf) {
      if (selectedAttachments.some((a) => a.id === attachment.id)) {
        setSelectedAttachments(selectedAttachments.filter((a) => a.id !== attachment.id));
      } else {
        setSelectedAttachments([...selectedAttachments, attachment]);
      }
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      key={attachment.id}
      ml={1}
      gap={0.5}
      sx={{
        cursor: disabled ? "default" : "pointer",
        marginTop: "2px",
        marginBottom: "2px",
        ...style,
      }}
      onClick={handleClick}
    >
      <StyledTooltip
        type={uploaded ? "success" : "warning"}
        title={
          uploaded
            ? "Ce document a déjà été intégré dans un sous-dossier"
            : !attachment.isPdf
              ? "Seuls les documents PDF sont autorisés"
              : ""
        }
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <span>
          {loading ? <CircularProgress size={16} /> :
            <CustomCheckbox
              size="small"
              checked={selectedAttachments.some((a) => a.id === attachment.id)}
              color={uploaded ? "success" : "warning"}
              sx={
                uploaded
                  ? {
                      ".MuiSvgIcon-root": {
                        color: theme.palette.success.main,
                      },
                    }
                  : undefined
              }
              disabled={disabled}
            />
          }
        </span>
      </StyledTooltip>
      <Box sx={{ opacity: disabled ? 0.5 : 1 }}>
        <FileIconType fileName={attachment.name} />
      </Box>

      <Typography fontSize={12} sx={{ color: disabled ? "gray" : "inherit", maxWidth: "75vw" }} noWrap>
        {attachment.name}
      </Typography>
    </Stack>
  );
}
