import * as React from "react";

import { Outlet } from "react-router-dom";

import { Header } from "./header";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";

export function Layout() {
  const matchDiagram = useMatch("/filiation/:client_id/:dossier_id/:sous_dossier_id/:flow_id");

  return (
    <Box height="100vh" sx={{ ...(matchDiagram ? { px: 0 } : { px: 1 }) }}>
      {matchDiagram ? null : <Header />}
      <Outlet />
    </Box>
  );
}
