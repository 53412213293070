import React from "react";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";

export const handleError = (error: unknown, consoleMesssage: string, notifiedMessage: string, optionalAction?: () => void): null => {
    console.error(consoleMesssage, error);

    enqueueSnackbar(notifiedMessage, {
        variant: "error",
        persist: true,
        action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
        )
    });

    if (optionalAction) {
        optionalAction();
    }

    return null
}