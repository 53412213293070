/* global Office */

import { UploadableFile } from "@/taskpane/types/file";

/**
 * Retrieves the content of an attachment.
 * @param attachmentId The ID of the attachment.
 * @returns A promise that resolves with the content of the attachment.
 */
export const getAttachmentContent = async (attachmentId: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    Office.context.mailbox.item?.getAttachmentContentAsync(attachmentId, { asyncContext: attachmentId }, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value.content);
      } else {
        reject(result.error);
      }
    });
  });
};

/**
 * Retrieves the attachments of the current email.
 * @returns A promise that resolves to an object containing the email ID and attachments.
 */
export const getAttachments = async (): Promise<{ emailId: string; attachments: UploadableFile[] }> => {
  const emailId = Office.context.mailbox.item?.itemId || "";
  const attachments = await Promise.all(
    (Office?.context?.mailbox?.item?.attachments?.filter((a) => !a.isInline) ?? [])?.map(async (a) => ({
      id: a.id,
      name: a.name,
      size: a.size,
      base64: await getAttachmentContent(a.id),
      type: a.contentType || "application/octet-stream",
    }))
  );
  return { emailId, attachments };
};
