/**
 * App Component
 *
 * This is the main component of the Outlook Add-in application.
 * It sets up the authentication context and routing for the app.
 *
 * The routing logic has been moved to the AppRoutes component for better organization.
 */
import React, { useMemo } from "react";

import { RouterProvider } from "react-router-dom";

import { router } from "@/taskpane/AppRoutes";
import { ThemeProvider } from "@mui/material";
import { theme } from "@/taskpane/utils/muiTheme";
import { AuthProvider } from "@/taskpane/contexts/auth/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SnackbarProvider from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { OfficeContextConsumer, OfficeContextProvider } from "@/taskpane/contexts/office/office-context";

export default function App() {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            throwOnError: true,
          },
        },
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SnackbarProvider>
            <OfficeContextProvider>
              <OfficeContextConsumer>
                {() =>
                  <RouterProvider router={router} />
                }
              </OfficeContextConsumer>
            </OfficeContextProvider>
          </SnackbarProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
