// @ts-nocheck

// Source: https://github.com/AsyncBanana/microdiff

interface Difference {
	type: "CREATE" | "REMOVE" | "CHANGE";
	path: (string | number)[];
	value?: any;
}
interface Options {
	cyclesFix: boolean;
}

const t = true;
const richTypes = { Date: t, RegExp: t, String: t, Number: t };

export function isEqual(oldObj: any, newObj: any): boolean {
	return (
		diff(
			{
				obj: oldObj,
			},
			{ obj: newObj }
		).length < 1
	);
}

export const isNotEqual = (oldObj: any, newObj: any) =>
	!isEqual(oldObj, newObj);

function diff(
	obj: Record<string, any> | any[],
	newObj: Record<string, any> | any[],
	options: Partial<Options> = { cyclesFix: true },
	_stack: Record<string, any>[] = []
): Difference[] {
	const diffs: Difference[] = [];
	const isObjArray = Array.isArray(obj);

	for (const key in obj) {
		const objKey = obj[key];
		const path = isObjArray ? Number(key) : key;
		if (!(key in newObj)) {
			diffs.push({
				type: "REMOVE",
				path: [path],
			});
			continue;
		}
		const newObjKey = newObj[key];
		const areObjects =
			typeof objKey === "object" && typeof newObjKey === "object";
		if (
			objKey &&
			newObjKey &&
			areObjects &&
			!richTypes[Object.getPrototypeOf(objKey).constructor.name] &&
			(options.cyclesFix ? !_stack.includes(objKey) : true)
		) {
			const nestedDiffs = diff(
				objKey,
				newObjKey,
				options,
				options.cyclesFix ? _stack.concat([objKey]) : []
			);
			// eslint-disable-next-line prefer-spread
			diffs.push.apply(
				diffs,
				nestedDiffs.map((difference) => {
					difference.path.unshift(path);

					return difference;
				})
			);
		} else if (
			objKey !== newObjKey &&
			!(
				areObjects &&
				(Number.isNaN(objKey)
					? String(objKey) === String(newObjKey)
					: Number(objKey) === Number(newObjKey))
			)
		) {
			diffs.push({
				path: [path],
				type: "CHANGE",
				value: newObjKey,
			});
		}
	}

	const isNewObjArray = Array.isArray(newObj);

	for (const key in newObj) {
		if (!(key in obj)) {
			diffs.push({
				type: "CREATE",
				path: [isNewObjArray ? Number(key) : key],
				value: newObj[key],
			});
		}
	}

	return diffs;
}


export const isObject = (item: any) => {
	return item && typeof item === 'object' && !Array.isArray(item);
}

export const areArraysEquals = (a, b) => {
	// If they point to the same instance of the array
	if (a === b) {
		return true;
	}

	// If they point to the same instance of date
	if (a instanceof Date && b instanceof Date) {
		return a.getTime() === b.getTime();
	}

	// If both of them are not null and their type is not an object
	if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
		return a === b;
	}

	// This means the elements are objects
	// If they are not the same type of objects
	if (a.prototype !== b.prototype) {
		return false;
	}

	// Check if both of the objects have the same number of keys
	const keys = Object.keys(a);
	if (keys.length !== Object.keys(b).length) {
		return false;
	}

	// Check recursively for every key in both
	return keys.every(k => areArraysEquals(a[k], b[k]));
};


