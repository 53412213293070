import { useGetFiliationQuery } from "@/taskpane/services/filiation.hook";
import { Property, PropertyNode, PropertyData } from "@/taskpane/types/filiation";
import { useMemo } from "react";

/**
 * Custom hook for retrieving and manipulating filiation data.
 *
 * @param folderId - The ID of the folder.
 * @param subFolderId - The ID of the subfolder.
 * @returns An object with the following properties:
 *  - communes: An array of communes.
 *  - lots: An array of lots.
 *  - parcellesWithNoChildren: An array of parcelles with no children.
 *  - volumesWithNoChildren: An array of volumes with no children.
 *  - getValidatedProperties: A function that returns the properties with a "chosen" property based on the selected IDs.
 */
export default function useFiliationData(folderId: string, subFolderId: string) {
  const { data: propertiesData, isLoading }: PropertyData | undefined | any = useGetFiliationQuery(
    folderId,
    subFolderId
  );
  const properties = (propertiesData && propertiesData["nodes"]) ?? [];
  const communes = useMemo(() => properties?.filter((p: { type: string }) => p.type === "commune"), [properties]);
  const parcelles = useMemo(() => properties?.filter((p: { type: string }) => p.type === "cadastre"), [properties]);
  const volumes = useMemo(() => properties?.filter((p: { type: string }) => p.type === "volume"), [properties]);

  const lots = useMemo(() => {
    const lotsData = properties
      ?.filter((p: { type: string }) => p.type === "lot")
      ?.map((p: any) => ({ ...p, children: [], parents: [] })) as PropertyNode[];

    for (const volume of volumes) {
      for (const lot of lotsData) {
        if (lot.parents_ids.includes(volume.id) && !lot.parents.some((p) => p.id === volume.id)) {
          lot.parents.push({ ...volume, children: [], parents: [] });
        }
      }
    }

    for (const parcelle of parcelles) {
      for (const lot of lotsData) {
        for (const volume of lot.parents) {
          if (volume.parents_ids.includes(parcelle.id) && !lot.parents.some((p) => p.id === parcelle.id)) {
            lot.parents.push({ ...parcelle, children: [], parents: [] });
          }
        }
        if (lot.parents_ids.includes(parcelle.id) && !lot.parents.some((p) => p.id === parcelle.id)) {
          lot.parents.push({ ...parcelle, children: [], parents: [] });
        }
      }
    }

    return lotsData;
  }, [properties]);

  const parcellesWithNoChildren = useMemo(() => {
    return parcelles
      .filter((p: { children_ids: string | any[] }) => p.children_ids.length === 0)
      .map((p: any) => ({ ...p, children: [], parents: [] }));
  }, [parcelles]);

  const volumesWithNoChildren = useMemo(() => {
    return volumes
      .filter((v: { children_ids: string | any[] }) => v.children_ids.length === 0)
      .map((v: Property) => ({
        ...v,
        children: [],
        parents: v.parents_ids
          .map((id) => parcelles.find((p: { id: number }) => p.id === id)!)
          .map((p) => ({ ...p, children: [], parents: [] })),
      }));
  }, [volumes]);

  return {
    communes,
    lots,
    parcellesWithNoChildren,
    volumesWithNoChildren,
    getValidatedProperties: (selected: number[]) => {
      const validatedProperties = properties.map((p: { id: number }) => ({ ...p, chosen: selected.includes(p.id) }));
      return validatedProperties;
    },
    isLoading,
  };
}
