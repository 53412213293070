export type FolderData = {
  dossier_id: string;
  dossier_name: string;
  dossier_status: string;
  dossier_intervenants_notaire: string[];
  created_on: string;
  updated_on: string;
};

export type Folder = {
  dossier_id: string;
  name: string;
  status: string;
  intervenants_notaires: string[];
  dossier_intervenants_notaire: string[];
  created_at: Date;
  updated_at: Date;
};

export function folderDataToFolder(folderData: FolderData): Folder {
  return {
    dossier_id: folderData.dossier_id,
    name: folderData.dossier_name,
    status: folderData.dossier_status,
    intervenants_notaires: folderData.dossier_intervenants_notaire,
    dossier_intervenants_notaire: folderData.dossier_intervenants_notaire,
    created_at: new Date(folderData.created_on),
    updated_at: new Date(folderData.updated_on),
  };
}

export type SubFolderData = {
  sous_dossier_id: string;
  index: number;
  sous_dossier_name: string;
  sous_dossier_nature: string; // maybe replace with enum?
  espace_notarial_connection_id: string;
  espace_notarial_status: string;
  espace_notarial_last_download: string;
};

export type SubFolder = {
  sous_dossier_id: string;
  index: number;
  name: string;
  nature: string; // maybe replace with enum?
  espace_notarial_connection_id: string;
  espace_notarial_status: string;
  espace_notarial_last_download: Date;
};

export function subFolderDataToSubFolder(subFolderData: SubFolderData): SubFolder {
  return {
    sous_dossier_id: subFolderData.sous_dossier_id,
    index: subFolderData.index,
    name: subFolderData.sous_dossier_name,
    nature: subFolderData.sous_dossier_nature,
    espace_notarial_connection_id: subFolderData.espace_notarial_connection_id,
    espace_notarial_status: subFolderData.espace_notarial_status,
    espace_notarial_last_download: new Date(subFolderData.espace_notarial_last_download),
  };
}
