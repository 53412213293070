import { apiClient } from "@/taskpane/utils/client";
import { handleError } from "@/taskpane/utils/errors";

export async function setUserCrpcen({ crpcen }: { crpcen: string }) {
  return (
    await apiClient.post("/user/set_crpcen", {
      crpcen,
    })
  ).data;
}

export async function getUserData() {
  try {
    const response = await apiClient.get("/user/current");
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /user/current failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Echec de l'authentification utilisateur`,  () => {
        const loginURL = `${window.location.origin}${window.location.pathname}?${window.location.search}#/login`;
        window.location.href = loginURL;
        window.localStorage.clear();
      });
  }
  // Return :
  // {
  //     "user_info": {
  //         "email": "tuan@bylaw.fr",
  //         "username_inot": "",
  //         "initiales_inot": "TA",
  //         "id_not": "",
  //         "groupes_inot": []
  //     }
  // }
}
