import React, { useCallback, useEffect, useMemo, useState } from "react";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

// import Logo from "@/taskpane/components/logo/Logo";
import useFiliationData from "@/taskpane/hooks/filiation";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import { useSetFiliationMutation } from "@/taskpane/services/filiation.hook";
import FiliationList from "@/taskpane/modules/folder/filiation/FiliationList";
import { Box, Button, Grid, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { useGetFoldersQuery, useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";

export default function Filiation() {
  const navigate = useNavigate();
  const { folderId, subFolderId } = useParams();
  const { data: folders } = useGetFoldersQuery();
  const { data: subFolders } = useGetSubFoldersQuery(folderId || "");
  const { communes, parcellesWithNoChildren, lots, volumesWithNoChildren, getValidatedProperties, isLoading } =
    useFiliationData(folderId || "", subFolderId || "");
  const { filiationData } = useOfficeContext();
  const [selectedCommune, setSelectedCommune] = useState(-1);
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);
  const theme = useTheme();
  const [filiationMutationLoading, setFiliationMutationLoading] = useState(false);
  const setFiliationMutation = useSetFiliationMutation({
    onSuccess: () => {
      enqueueConfirmSnackbar(
        "Envoi terminé, l’analyse est en cours et les résultats seront directement intégrés dans iNot.",
        () => {
          setFiliationMutationLoading(false);
          navigate("/folders");
        },
        "Compris",
        {
          persist: true,
        }
      );
    },
    onError: (error) => {
      setFiliationMutationLoading(false);
      enqueueSnackbar(typeof error === "string" ? error : (error?.message ?? "An unexpect error occured"), {
        variant: "error",
        persist: true,
        action: (snackbarId: SnackbarKey | undefined) => (
          <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
        ),
      });
    },
  });

  const folder = folders?.find((f) => f?.dossier_id === folderId);
  const subFolder = subFolders?.find((sf) => sf?.sous_dossier_id === filiationData?.sous_dossier_id);

  /**
   * Pre-rendering filtering of lots, parcelles, and volumes fetched data
   * that adapt thier content depending on the selected commune.
   * @type {Object} Filtered lots, parcelles, and volumes based on seleceted commune.
   */
  const filteredData = useMemo(() => {
    return {
      filteredLots: lots.filter((lot) => {
        if (selectedCommune >= 0) {
          return lot.parents.some((parent) => parent.parents_ids.includes(selectedCommune));
        }
        return true;
      }),
      filteredParcelles: parcellesWithNoChildren.filter((parcelle: { parents_ids: number[] }) => {
        if (selectedCommune >= 0) {
          return parcelle.parents_ids.includes(selectedCommune);
        }
        return true;
      }),
      filteredVolumes: volumesWithNoChildren.filter((volume: { parents: any[] }) => {
        if (selectedCommune >= 0) {
          return volume.parents.some((parent) => parent.parents_ids.includes(selectedCommune));
        }
        return true;
      }),
    };
  }, [lots, selectedCommune, parcellesWithNoChildren, volumesWithNoChildren]);

  const handleSelectProperty = useCallback(
    (ids: number[], checked: boolean) => {
      if (checked) {
        setSelectedProperties((prev) => [...prev, ...ids]);
      } else {
        setSelectedProperties((prev) => prev.filter((id) => !ids.includes(id)));
      }
    },
    [selectedProperties]
  );

  /**
   * Side effect that updates the selected properties of each lots when the user manually select a lot.
   * This effect is responsible to update the checkbox in render to representing a user choice and
   * adding it to already chosen lots.
   * @param {number[]} ids - IDs of selected lots
   * @param {boolean} checked - Whether the properties are being selected or deselected
   */
  useEffect(() => {
    if (lots && lots?.length && lots.length > 0) {
      const chosenLots = lots
        .map((chosenLot) => {
          if (chosenLot.chosen === true) {
            return chosenLot.id;
          } else {
            return null;
          }
        })
        .filter((chosenLot) => chosenLot !== null);
      setSelectedProperties((prev) => [...prev, ...chosenLots]);
    }
  }, [lots]);

  /**
   * Side effect that track changes of the parcelle received in the filiation
   * from the API.
   *
   * This effect is responsible to update the selected parcelles with parcelles
   * from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what parcelles are already chose before making
   * the filiation choice.
   *
   * @dependency {obj[]} parcellesWithNoChildren - Parcelles received from the API
   * in the filiation.
   */
  useEffect(() => {
    if (parcellesWithNoChildren && parcellesWithNoChildren?.length && parcellesWithNoChildren.length > 0) {
      const chosenParcellesWithNoChildren = parcellesWithNoChildren
        .map((chosenParcelle) => {
          if (chosenParcelle.chosen === true) {
            return chosenParcelle.id;
          } else {
            return null;
          }
        })
        .filter((chosenParcelle) => chosenParcelle !== null);
      setSelectedProperties((prev) => [...prev, ...chosenParcellesWithNoChildren]);
    }
  }, [parcellesWithNoChildren]);

  /**
   * Side effect that track changes of the volumes received in the filiation
   * from the API.
   *
   * This effect is responsible to update the selected volumes with volumes
   * from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what volumes are already chose before making
   * the filiation choice.
   *
   * @dependency {obj[]} volumesWithNoChildren - volumes received from the API
   * in the filiation.
   */
  useEffect(() => {
    if (volumesWithNoChildren && volumesWithNoChildren?.length && volumesWithNoChildren.length > 0) {
      const chosenVolumes = volumesWithNoChildren
        .map((chosenVolume) => {
          if (chosenVolume.chosen === true) {
            return chosenVolume.id;
          } else {
            return null;
          }
        })
        .filter((chosenVolume) => chosenVolume !== null);
      setSelectedProperties((prev) => [...prev, ...chosenVolumes]);
    }
  }, [volumesWithNoChildren]);

  return (
    <Stack p={0} gap={1}>
      {/* <Logo /> */}
      <Box>
        <Typography fontSize={12} color="secondary.500" fontWeight={600} sx={{ maxWidth: "75vw" }} noWrap>
          Dossier: {folder?.name}
        </Typography>
        <Typography fontSize={12} color="secondary.400" fontWeight={600} sx={{ maxWidth: "75vw" }} noWrap>
          Sous-dossier: {subFolder?.name}
        </Typography>
      </Box>
      <Typography fontSize={14} color="primary.500" fontWeight={600}>
        Sélection des biens objets de la transaction :
      </Typography>
      <Box
        flexGrow={1}
        height={1}
        sx={{
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          p: 0.5,
          pl: 1,
          opacity: filiationMutationLoading ? 0.5 : 1,
          pointerEvents: filiationMutationLoading ? "none" : "auto",
        }}
      >
        <StyledSelect
          label="Commune"
          borderColor={theme.palette.grey[900]}
          value={selectedCommune}
          onChange={(e) => setSelectedCommune(Number(e.target.value))}
        >
          <MenuItem value={-1}>Toutes les communes</MenuItem>
          {communes?.map((c: any) => (
            <MenuItem key={c.id} value={c.id}>
              {c.value}
            </MenuItem>
          ))}
        </StyledSelect>
        <Grid container spacing={0.5} direction="column" height={1} pt={2} pb={0} minHeight={0}>
          <Grid item xs={3} minHeight={0}>
            <FiliationList
              color="success"
              title="Parcelles entières :"
              noDataMessage="Aucune parcelle à afficher"
              indicator="(seules les parcelles ne contenant aucun volume ni lot sont affichées)"
              data={filteredData.filteredParcelles}
              type="cadastre"
              selectedProperties={selectedProperties}
              onSelectProperty={handleSelectProperty}
              loading={isLoading}
              colNum={0}
            />
          </Grid>
          <Grid item xs={3} minHeight={0}>
            <FiliationList
              color="warning"
              title="Volumes entiers :"
              noDataMessage="Aucun volume à afficher"
              indicator="(seuls les volumes ne contenant aucun lot sont affichés)"
              data={filteredData.filteredVolumes}
              type="volume"
              selectedProperties={selectedProperties}
              onSelectProperty={handleSelectProperty}
              loading={isLoading}
              colNum={2}
            />
          </Grid>
          <Grid item xs={6} minHeight={0}>
            <FiliationList
              color="primary"
              title="Lots entiers :"
              noDataMessage="Aucun lot à afficher"
              indicator="(vous pouvez filtrer les lots par parcelle et par volume)"
              data={filteredData.filteredLots}
              type="lot"
              selectedProperties={selectedProperties}
              onSelectProperty={handleSelectProperty}
              loading={isLoading}
              colNum={3}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" gap={3} justifyContent="center" my={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/folders")}
          disabled={filiationMutationLoading}
        >
          Annuler
        </Button>
        <WarningTooltip title={selectedProperties.length ? "" : "Vous devez sélectionner au moins un bien"}>
          <span>
            <Button
              variant="outlined"
              color="success"
              disabled={!selectedProperties.length || filiationMutationLoading}
              onClick={() => {
                setFiliationMutationLoading(true);
                setFiliationMutation.mutate({
                  folderId: folderId || "",
                  subFolderId: subFolderId || "",
                  properties: getValidatedProperties(selectedProperties),
                });
              }}
            >
              Valider
            </Button>
          </span>
        </WarningTooltip>
      </Stack>
    </Stack>
  );
}
