"use client";

import React, { useRef } from "react";

import { closeSnackbar, enqueueSnackbar, SnackbarProvider as NotistackProvider } from "notistack";

import { Button, IconButton } from "@mui/material";
import { FaCheckCircle } from 'react-icons/fa'; // Correct import for check circle icon
import { BsFillExclamationOctagonFill } from "react-icons/bs";
import CloseIcon from '@mui/icons-material/Close';

import { StyledIcon, StyledNotistack } from "./styles";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

/**
 * SnackbarProvider component for managing and displaying snackbars (toasts).
 * 
 * This component wraps its children with Notistack's SnackbarProvider to provide
 * a context for displaying snackbars. It sets default properties and custom icons
 * for different variants of snackbars.
 * 
 * @component
 * @param {Props} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the SnackbarProvider.
 * @returns {JSX.Element} The rendered SnackbarProvider component.
 */
export default function SnackbarProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={1}
      preventDuplicate
      autoHideDuration={null}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      action={(snackbarId) => (
        <IconButton aria-label="close" color="error" onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon />
        </IconButton>
      )}
      iconVariant={{
        success: (
          <StyledIcon color="success">
            <FaCheckCircle width={24} />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color="error">
            <BsFillExclamationOctagonFill width={24} />
          </StyledIcon>
        ),
      }}
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
      }}
    >
      {children}
    </NotistackProvider>
  );
}

/**
 * Enqueues a confirm snackbar with the specified message and button label. Encapsulated to avoid
 * code duplication.
 *
 * @param message - The message to display in the snackbar.
 * @param onClick - The function to be called when the button is clicked.
 * @param buttonLabel - The label to display on the button. Default value is "Compris".
 */
export function enqueueConfirmSnackbar(message: string, onClick: () => void, buttonLabel = "Compris", opts: object = {}) {
  const snackbar = enqueueSnackbar(message, {
    variant: opts?.variant ?? "success",
    action: (
      <Button
        variant="outlined"
        color={opts?.variant ?? "success"}
        onClick={() => {
          onClick();
          closeSnackbar(snackbar);
        }}
      >
        {buttonLabel}
      </Button>
    ),
    persist: true,
    ...opts
  });
}
