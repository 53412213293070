import { apiClient } from "@/taskpane/utils/client";
import { GetNotarySpaceStatusResponse, TestNotarySpaceResponse } from "@/taskpane/types/notary-space";

export type TestNotarySpacePayload = {
  folderId: string;
} & (
  | {
      connection_id: string;
    }
  | {
      url: string;
      identifiant: string;
      mot_de_passe: string;
      sous_dossier_id: string;
    }
);

export async function testNotarySpaceConnection({
  folderId,
  ...payload
}: TestNotarySpacePayload): Promise<TestNotarySpaceResponse> {
  return (await apiClient.post(`/dossier/${folderId}/espace-notarial/test-connection`, payload)).data;
}

export async function getNotarySpaceConnectionStatus({
  folderId,
  connectionId,
}: {
  folderId: string;
  connectionId: string;
}): Promise<GetNotarySpaceStatusResponse> {
  return (await apiClient.get(`/dossier/${folderId}/espace-notarial/connection-status/${connectionId}`)).data;
}

export async function associateDataroomToFolder({
  folderId,
  connectionId,
  dataRoomId,
}: {
  folderId: string;
  connectionId: string;
  dataRoomId: string;
}): Promise<void> {
  return (
    await apiClient.post(`/dossier/${folderId}/espace-notarial/associate`, {
      connection_id: connectionId,
      dataroom_id: dataRoomId,
    })
  ).data;
}
