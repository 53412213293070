import { Node, NodeTypes } from "@xyflow/react";

import { BaseNode } from "@/diagramme/components/nodes/base-node";
import { GroupedNode } from "@/diagramme/components/nodes/grouped-node";

export type GroupNode = Node<
	{
		group?: any[];
	},
	"group"
>;

export const kNodeTypes: NodeTypes = {
	base: BaseNode,
	group: GroupedNode,
};

export * from "@/diagramme/components/nodes/base-node";
export * from "@/diagramme/components/nodes/grouped-node";
