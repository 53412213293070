import App from "@/taskpane/App";
import * as React from "react";
import { createRoot } from "react-dom/client";
import "./App.css";

/* global Office */

const rootElement = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  root?.render(<App />);
});

// @ts-expect-error typeof module does contain hot
if (module.hot) {
  // @ts-expect-error typeof module does contain hot
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    root?.render(<NextApp />);
  });
}
