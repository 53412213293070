import { PropertyType } from "@/taskpane/types/filiation";

/**
 * Converts a property type to its corresponding label.
 *
 * @param type - The property type to convert.
 * @returns The label corresponding to the given property type.
 */
export const typeToLabel = (type: PropertyType) => {
  switch (type) {
    case "volume":
      return "Volume";
    case "lot":
      return "Lot";
    case "cadastre":
      return "Parcelle";
    default:
      return "";
  }
};

/**
 * Converts a property type to its corresponding color.
 *
 * @param type - The property type to convert.
 * @returns The color corresponding to the given property type.
 */
export const typeToColor = (type: PropertyType) => {
  switch (type) {
    case "volume":
      return "warning";
    case "lot":
      return "primary";
    case "cadastre":
      return "success";
    default:
      return undefined;
  }
};

export const sortNumberOrString = (dataArray: any[], order: "asc" | "desc" = "asc") => {
  // Utility function to split a string into an array of numbers and non-numbers
  const splitAlphanumeric = (str: string) => {
      return str.split(/(\d+)/).map(part => (isNaN(Number(part)) ? part : Number(part)));
  };

  // Comparison function for natural sorting
  const naturalCompare = (a: any, b: any) => {
      let aStr = typeof a === 'object' ? a.value.toString() : a.toString();
      let bStr = typeof b === 'object' ? b.value.toString() : b.toString();

      const aParts = splitAlphanumeric(aStr);
      const bParts = splitAlphanumeric(bStr);

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
          if (aParts[i] != undefined && bParts[i] != undefined) {
              // If both parts are numbers
              if (typeof aParts[i] === 'number' && typeof bParts[i] === 'number') {
                  if (aParts[i] !== bParts[i]) {
                      return aParts[i] - bParts[i];
                  }
              }
              // If both parts are strings
              else if (typeof aParts[i] === 'string' && typeof bParts[i] === 'string') {
                  if (aParts[i] !== bParts[i]) {
                      return aParts[i].localeCompare(bParts[i]);
                  }
              }
              // If parts are different types, prioritize non-undefined part
              else {
                  return typeof aParts[i] === 'number' ? -1 : 1;
              }
          } else {
              return aParts[i] == undefined ? -1 : 1;
          }
      }
      return 0;
  };

  const sortedArray = dataArray.slice().sort((a, b) => {
      const comparison = naturalCompare(a, b);
      return order === "asc" ? comparison : -comparison;
  });

  return sortedArray;
};
