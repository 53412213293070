export type EspaceNotarialStatus =
  | "connection_pending"
  | "connection_success"
  | "connection_fail"
  | "connection_fail_wrong_credentials"
  | "refresh_pending"
  | "refresh_in_progress"
  | "refresh_success"
  | "refresh_fail_wrong_credentials"
  | "refresh_fail_dataroom_not_found"
  | "refresh_fail"
  | "download_pending"
  | "download_in_progress"
  | "download_fail_wrong_credentials"
  | "download_success"
  | "download_fail";

export type ShieldType = "success" | "warning" | "error" | "disabled";

export enum NotarySpaceSteps {
  SELECT_SUBFOLDER,
  LOGIN,
  SELECT_DATAROOM,
  DONE,
}

export type ConnectionStatus =
  | "connection_pending"
  | "connection_success"
  | "connection_fail_wrong_credentials"
  | "connection_fail"
  | "download_pending"
  | "download_in_progress"
  | "download_success";

export interface TestNotarySpaceResponse {
  connection_id: string;
  espace_notarial_status: ConnectionStatus;
}

export interface Dataroom {
  dataroom_id: string;
  dataroom_name: string;
}

export interface GetNotarySpaceStatusResponse {
  connection_id: string;
  espace_notarial_status: ConnectionStatus;
  all_datarooms: Dataroom[];
}
